import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import App from './App';
import ErrorBoundary from './components/ErrorBoundary';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import './scss/main.scss';

export const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      process.env.REACT_APP_BUILD_TARGET_ENV !== 'dev'
        ? process.env.REACT_APP_APP_INSIGHTS_PROD_CONNECTION_STRING
        : process.env.REACT_APP_APP_INSIGHTS_DEV_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    enableUnhandledPromiseRejectionTracking: true,
    disableExceptionTracking: false,
  },
});

appInsights.loadAppInsights();
appInsights.trackPageView();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <PersistQueryClientProvider
    client={queryClient}
    persistOptions={{ persister }}
  >
    <ErrorBoundary appInsights={appInsights}>
      <App />
    </ErrorBoundary>
  </PersistQueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
