/* eslint-disable no-param-reassign */
import React, { useState, useMemo } from 'react';
import PropTypes, { number, string, bool } from 'prop-types';

import { Col } from 'react-bootstrap';
import { format } from 'date-fns';
import { findLast } from 'lodash';
import ActivityGrid from './ActivityGrid';
import ActivityStatusRules from './ActivityStatusRules';
import ActivityGridFilters from './ActivityGridFilters';
import { useActivities } from '../../queries/assetQueries';

import {
  titleCase,
  getBadgeColor,
  DATE_RENDER_FORMAT,
} from '../../shared/helpers';

function ActivityGridContainer(props) {
  const [filteredLines, setFilteredLines] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);
  const [statusRules, setStatusRules] = useState({
    preSalt: 0,
    pass: 0,
    clear: 0,
    salted: 0,
  });
  const { lineData, assetData, inspectionTestData } = props;

  const { data: activityData, refetch: refetchActivities } = useActivities();
  const preparedAssets = useMemo(() => {
    /* Creates lookup object of { lineId, lineDescription }
     *  where 'description' is the line's name.
     *  Loops through the assets and attaches the line's name to
     *  the asset object.
     *
     */

    if (lineData && assetData && activityData) {
      const lineLookup = lineData?.reduce((prev, current) => {
        prev[current.id] = titleCase(current.description);
        return prev;
      }, {});

      const assetCopy = [...assetData];

      const assetsWithDisplayNames = assetCopy?.map((asset) => {
        if (asset.lineId in lineLookup) {
          asset.displayName = lineLookup[asset.lineId];
        }

        const associatedActivities = activityData?.filter(
          (activity) => activity.assetId === asset.id && activity.id !== 189
        );
        asset.activities = associatedActivities;

        if (associatedActivities.length >= 1) {
          const lastActivityTime = new Date(
            associatedActivities[
              associatedActivities.length - 1
            ].testResults[0].datetimeTested
          );

          const formattedLastActivity =
            format(lastActivityTime, DATE_RENDER_FORMAT) || 'N/A';
          asset.lastActivityTime = lastActivityTime;
          asset.lastActivity = formattedLastActivity;
        }

        /* The following lines are responsible for looking up the most recent
         * of each version of activity: pre-salt, pass, clear, salt, clear & salted
         * and generating the appropriate badge color for the asset table.
         * There is additional logic to handle the priority of clear & salted activities
         * vs the standalone clear and salt activities to ensure that
         * the most recent of each type is reflected in the UI.
         */

        const lastPreSalt =
          findLast(
            associatedActivities,
            (activity) =>
              activity.testResults[0]?.qualitativeFinding === 'PRE-SALT'
          ) || false;

        const preSaltBadge = getBadgeColor(statusRules.preSalt, lastPreSalt);
        asset.preSaltBadge = preSaltBadge;

        const lastPass =
          findLast(
            associatedActivities,
            (activity) => activity.testResults[0]?.qualitativeFinding === 'PASS'
          ) || false;

        const passBadge = getBadgeColor(statusRules.pass, lastPass);
        asset.passBadge = passBadge;

        const lastClearSalt =
          findLast(
            associatedActivities,
            (activity) =>
              activity.testResults[0]?.qualitativeFinding === 'CLEAR & SALTED'
          ) || false;

        const lastClear =
          findLast(
            associatedActivities,
            (activity) =>
              activity.testResults[0]?.qualitativeFinding === 'CLEAR'
          ) || false;
        const lastSalt =
          findLast(
            associatedActivities,
            (activity) =>
              activity.testResults[0]?.qualitativeFinding === 'SALTED'
          ) || false;

        if (lastClearSalt && (lastClear || lastSalt)) {
          const lastClearSaltTime = new Date(
            lastClearSalt.testResults[0].datetimeTested
          );
          // if there is a clear and salt in the asset's history
          // but also a clear or a salt, we need to see which is newer
          // so as to properly color the table badges
          if (lastClear) {
            const lastClearTime = new Date(
              lastClear?.testResults[0].datetimeTested
            );
            if (lastClearSaltTime.getTime() > lastClearTime.getTime()) {
              // last salt and clear is newer and takes priority
              const clearBadge = getBadgeColor(
                statusRules.clear,
                lastClearSalt
              );
              asset.clearBadge = clearBadge;
            } else {
              // last salt job is newer and takes priority
              const clearBadge = getBadgeColor(statusRules.clear, lastClear);
              asset.clearBadge = clearBadge;
            }
          }

          if (lastSalt) {
            const lastSaltTime = new Date(
              lastSalt.testResults[0].datetimeTested
            );
            if (lastClearSaltTime.getTime() > lastSaltTime.getTime()) {
              const saltBadge = getBadgeColor(
                statusRules.salted,
                lastClearSalt
              );
              asset.saltBadge = saltBadge;
            } else {
              const saltBadge = getBadgeColor(statusRules.salted, lastSalt);
              asset.saltBadge = saltBadge;
            }
          } else {
            const saltBadge = getBadgeColor(statusRules.salted, lastSalt);
            asset.saltBadge = saltBadge;
          }
        } else {
          const saltBadge = getBadgeColor(statusRules.salted, lastClearSalt);
          const clearBadge = getBadgeColor(statusRules.clear, lastClearSalt);
          asset.saltBadge = saltBadge;
          asset.clearBadge = clearBadge;
        }

        if (!lastClearSalt) {
          const clearBadge = getBadgeColor(statusRules.clear, lastClear);
          const saltBadge = getBadgeColor(statusRules.salted, lastSalt);

          asset.clearBadge = clearBadge;
          asset.saltBadge = saltBadge;
        }

        const thisMilepost = asset?.attributes?.find(
          (attribute) => attribute.name === 'TRMS MILEPOST'
        );

        asset.milepost = thisMilepost?.textValue || 'N/A';

        // stopgap for assets without an associated line to make the UI work
        if (!asset.lineId) {
          asset.displayName = 'Terminal Line (WIP)';
          asset.lineId = 'TER';
        }

        const badgeArray = [
          asset.passBadge,
          asset.saltBadge,
          asset.clearBadge,
          asset.preSaltBadge,
        ];

        // if any badge is not green, the asset is 'alert'
        const alertCheck =
          badgeArray.includes('warning') || badgeArray.includes('danger');
        // if every badge is green, the asset is 'ok
        const okCheck =
          badgeArray.includes('success') &&
          !badgeArray.includes('warning') &&
          !badgeArray.includes('danger');
        if (okCheck === true) {
          asset.noAlerts = true;
        } else {
          asset.noAlerts = false;
        }

        if (alertCheck === true) {
          asset.alert = true;
        } else {
          asset.alert = false;
        }

        return asset;
      });

      return assetsWithDisplayNames;
    }
    return false;
  }, [
    lineData,
    assetData,
    activityData,
    statusFilters,
    statusRules.clear,
    statusRules.pass,
    statusRules.preSalt,
    statusRules.salted,
  ]);

  // Manages the array of filtered lines
  const handleLineFilter = (line) => {
    const currentFilters = [...filteredLines];
    if (currentFilters.includes(line)) {
      const newFilters = currentFilters.filter((thing) => thing !== line);
      setFilteredLines(newFilters);
      return;
    }
    currentFilters.push(line);
    setFilteredLines(currentFilters);
  };

  // Manages the array of filtered assets
  const handleAssetFilter = (asset) => {
    const currentFilters = [...filteredAssets];
    if (currentFilters.includes(asset)) {
      const newFilters = currentFilters.filter((thing) => thing !== asset);
      setFilteredAssets(newFilters);
      return;
    }
    currentFilters.push(asset);
    setFilteredAssets(currentFilters);
  };

  // Manages the 'OK' and 'Alert' filters
  const handleOkAlertFilter = (selection) => {
    const currentFilters = [...statusFilters];
    if (currentFilters.includes(selection)) {
      const newFilters = currentFilters.filter((thing) => thing !== selection);
      setStatusFilters(newFilters);
      return;
    }
    currentFilters.push(selection);
    setStatusFilters(currentFilters);
  };

  let filteredByRoute = [];
  let currentVisibleAssets = [];
  if (preparedAssets) {
    // filter all assets by selected lines
    // this variable is always used; if there is no line filter, the whole
    // array of prepared assets makes it through
    filteredByRoute = preparedAssets?.filter((asset) => {
      if (filteredLines.length === 0) {
        return asset;
      }
      return filteredLines.includes(asset.lineId);
    });

    // generate distinct array of assets from the selected lines
    // necessary that this happens after filtering by lines to generate the visible assets
    // for the option buttons on the right
    currentVisibleAssets = filteredByRoute?.reduce((prev, current) => {
      if (!prev.includes(current.categoryId)) {
        prev.push(current.categoryId);
      }
      return prev;
    }, []);
  }

  /*
   * It is possible to de-select a line that will also cause an asset
   * type to no longer be filterable.
   * Check the stateful array of filtered assets
   * against the currently possible assets associated with
   * the selected lines and overwrite asset filter
   * if necessary
   */

  let filterOverwrite = false;
  const filterCheck = [...filteredAssets].filter((asset) => {
    if (currentVisibleAssets.includes(asset)) {
      return asset;
    }
    filterOverwrite = true;
    return false;
  });

  if (filterOverwrite) {
    setFilteredAssets(filterCheck);
  }

  // filter the whole line's worth of assets by user selected asset types
  // this variable is always used; if there is no asset filter selected
  // the entire array makes it through
  const filterByAsset = filteredByRoute?.filter((filteredAsset) => {
    if (filteredAssets.length === 0) {
      return filteredAsset;
    }
    return filteredAssets.includes(filteredAsset.categoryId);
  });

  const filterByStatus = filterByAsset?.filter((filteredAsset) => {
    if (statusFilters.length === 0) {
      return filteredAsset;
    }

    if (statusFilters.includes('ok') && filteredAsset.noAlerts === true) {
      return filteredAsset;
    }

    if (statusFilters.includes('alert') && filteredAsset.alert === true) {
      return filteredAsset;
    }

    return false;
  });

  const alphabetizedByLine = filterByStatus?.sort((a, b) => {
    if (!a.displayName || !b.displayName) {
      return null;
    }
    return a.displayName
      .toLowerCase()
      .localeCompare(b.displayName.toLowerCase());
  });

  const handleStatusRulesChange = (name, value) => {
    setStatusRules({ ...statusRules, [name]: value });
  };

  const resetStatusRules = () => {
    setStatusRules({
      preSalt: 0,
      pass: 0,
      clear: 0,
      salted: 0,
    });
  };

  return (
    <>
      <Col sm={8} xxl={9}>
        <ActivityGrid
          assets={alphabetizedByLine}
          statusRules={statusRules}
          lineData={lineData}
          assetData={assetData}
          inspectionTestData={inspectionTestData}
          refetchActivities={refetchActivities}
        />
      </Col>
      <Col sm={4} xxl={3}>
        <ActivityStatusRules
          statusRules={statusRules}
          handleStatusRulesChange={handleStatusRulesChange}
          resetStatusRules={resetStatusRules}
        />
        <ActivityGridFilters
          lines={lineData}
          assets={assetData}
          handleLineFilter={handleLineFilter}
          handleAssetFilter={handleAssetFilter}
          handleOkAlertFilter={handleOkAlertFilter}
          statusFilters={statusFilters}
          filteredLines={filteredLines}
          filteredAssets={filteredAssets}
          currentVisibleAssets={currentVisibleAssets}
        />
      </Col>
      {/* <Modal show={showActivityEdit} size="lg">
        <ActivityEditModal handleClose={handleClose} />
      </Modal> */}
    </>
  );
}

export default ActivityGridContainer;

ActivityGridContainer.defaultProps = {
  lineData: [],
  assetData: [],
  inspectionTestData: [],
};

ActivityGridContainer.propTypes = {
  lineData: PropTypes.arrayOf(
    PropTypes.shape({
      eamId: number,
      id: string,
      description: string,
    })
  ),
  assetData: PropTypes.arrayOf(
    PropTypes.shape({
      activities: PropTypes.arrayOf(
        PropTypes.shape({
          assetId: string,
          comments: string,
          id: number,
          jobType: string,
          taskId: string,
          testResults: PropTypes.arrayOf(
            PropTypes.shape({
              comments: string,
              datetimeTested: string,
              id: number,
              imagePath: string,
              qualitativeFinding: string,
              testElementId: string,
              workOrderId: number,
            })
          ),
          userId: string,
        })
      ),
      assetClass: string,
      assetNumber: string,
      attributes: PropTypes.arrayOf(
        PropTypes.shape({
          active: string,
          assetId: string,
          dateValue: string,
          displayOrder: number,
          id: number,
          name: string,
          numericValue: number,
          subsystem: string,
          textValue: string,
          value: string,
        })
      ),
      categoryId: string,
      description: string,
      displayName: string,
      eamId: number,
      id: string,
      lastClear: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lastPass: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lastPreSalt: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lastSalt: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lineId: string,
      milepost: string,
      serialNumber: string,
      title: string,
      type: string,
    })
  ),
  inspectionTestData: PropTypes.arrayOf(
    PropTypes.shape({
      allowFile: bool,
      assetClass: string,
      checklist: PropTypes.arrayOf(
        PropTypes.shape({
          id: number,
          checklistType: string,
          value: string,
          description: string,
        })
      ),
      checklistType: string,
      description: string,
      displayOrder: number,
      eamId: number,
      id: string,
      requireFile: bool,
      testId: string,
    })
  ),
};
