import React, { useState } from 'react';
import { Button, Modal, Stack, Col, Row, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import formatDate from 'date-fns/format';
import { useAssets } from '../queries/assetQueries';
import { getDatedActivities } from '../api';
import {
  getDateStringForInput,
  getDateFromInputString,
  generateActivityCSV,
  generateAssetCSV,
} from '../shared/helpers';

function DownloadDataModal() {
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { data: assets } = useAssets();
  const [reportReady, setReportReady] = useState(false);
  const [report, setReport] = useState([]);
  const [reportName, setReportName] = useState('export');

  const handleCloseDownloadModal = () => {
    setShowDownloadModal(false);
    setReport([]);
    setReportReady(false);
    setReportName('export');
  };

  const onSubmit = async (data) => {
    if (reportReady) {
      setReportReady(false);
    }
    const startDate = new Date(data.startDate);
    const endDate = new Date(data.endDate);
    const response = await getDatedActivities(
      startDate.toISOString(),
      endDate.toISOString()
    );
    const dateFormat = 'yyyyMMdd';

    if (data.type === 'activities') {
      const activityReport = generateActivityCSV(response);
      setReport(activityReport);
      setReportReady(true);

      setReportName(
        `activities-export-${formatDate(startDate, dateFormat)}-${formatDate(
          endDate,
          dateFormat
        )}.csv`
      );

      return;
    }
    const activityReport = generateAssetCSV(response, assets);
    setReport(activityReport);
    setReportReady(true);

    setReportName(
      `assets-export-${formatDate(startDate, dateFormat)}-${formatDate(
        endDate,
        dateFormat
      )}.csv`
    );
  };

  return (
    <>
      <Button onClick={() => setShowDownloadModal(true)} className="me-3">
        Download Data
      </Button>{' '}
      <Modal show={showDownloadModal} size="xl" centered>
        <Modal.Title className="text-center">
          <Stack
            direction="horizontal"
            className="justify-content-space-between bg-secondary text-white"
          >
            <h4 className="ms-auto text-white pt-2">Export SNAPP Data</h4>
            <a
              href
              className="ms-auto text-white px-2"
              onClick={(event) => {
                event.preventDefault();
                handleCloseDownloadModal();
              }}
            >
              <FontAwesomeIcon icon={faXmark} color="#ffffff" size="lg" />
            </a>
          </Stack>
        </Modal.Title>
        <Modal.Body>
          <Row className="mb-5">
            <Col>
              <Form.Group className="mb-3" controlId="startDate">
                <Form.Label>Select Start Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  max={getDateStringForInput(new Date())}
                  {...register('startDate', {
                    required: true,
                    validate: (v) => {
                      const date = getDateFromInputString(v);
                      return date <= new Date()
                        ? true
                        : 'Cannot be a future date';
                    },
                  })}
                  isInvalid={!!errors.startDate}
                  feedback="Error message"
                  feedbackType="invalid"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="startDate">
                <Form.Label>Select End Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  max={getDateStringForInput(new Date())}
                  {...register('endDate', {
                    required: true,
                    validate: (v) => {
                      const date = getDateFromInputString(v);
                      return date <= new Date()
                        ? true
                        : 'Cannot be a future date';
                    },
                  })}
                  isInvalid={!!errors.endDate}
                  feedback="Error message"
                  feedbackType="invalid"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Select Collection</Form.Label>
                <Form.Select {...register('type', { required: true })}>
                  <option value="assets">SNAPP Assets</option>
                  <option value="activities">SNAPP Activities</option>
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>
          <Button
            className="mt-5"
            variant="primary"
            onClick={handleSubmit(onSubmit)}
            disabled={reportReady}
          >
            Generate
          </Button>
          {reportReady && (
            <div className="mt-3">
              <CSVLink data={report} filename={reportName}>
                Download {reportName}
              </CSVLink>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DownloadDataModal;
