export const API_ROOT = `${window.location.origin}/api`;

export async function getAssets() {
  return fetch(`${API_ROOT}/core/assets`).then((response) => {
    if (response.ok) {
      return response.json();
    }

    throw new Error('Error fetching assets');
  });
}

export async function getLines() {
  return fetch(`${API_ROOT}/core/lines`).then((response) => {
    if (response.ok) {
      return response.json();
    }

    throw new Error('Error fetching lines');
  });
}

export async function getInspectionTests() {
  return fetch(`${API_ROOT}/core/inspection-tests`).then((response) => {
    if (response.ok) {
      return response.json();
    }

    throw new Error('Error fetching inspection tests');
  });
}

export async function getActivities() {
  return fetch(`${API_ROOT}/core/activities`).then((response) => {
    if (response.ok) {
      return response.json();
    }

    throw new Error('Error fetching activities');
  });
}

export async function getDatedActivities(startDate, endDate) {
  return fetch(
    `${API_ROOT}/core/activities?startDate=${startDate}&endDate=${endDate}`
  ).then((response) => {
    if (response.ok) {
      return response.json();
    }

    throw new Error('Error fetching activities');
  });
}

export async function saveActivity(activity) {
  return fetch(`${API_ROOT}/core/activities`, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(activity),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Error saving activity');
  });
}

export async function updateActivity(activity, activityId) {
  return fetch(`${API_ROOT}/core/activities/${activityId}`, {
    method: 'PUT',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(activity),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Error updating activity');
  });
}

export async function saveImage(image, workOrderId = null) {
  return fetch(
    `${API_ROOT}/core/activities/${
      workOrderId || image.workOrderId
    }/test-results/${image.testElementId}/image`,
    {
      method: 'POST',
      headers: { 'content-type': 'text/plain' },
      body: image.dataUrl,
    }
  ).then((response) => {
    if (response.ok) {
      return true;
    }
    throw new Error('Error saving image');
  });
}

export async function getImage(workOrderId, testElementId) {
  return fetch(
    `${API_ROOT}/core/activities/${workOrderId}/test-results/${testElementId}/image`
  ).then((response) => {
    if (response.ok) {
      return response.blob();
    }
    throw new Error('Error retreiving image');
  });
}

export async function getAllUsers() {
  return fetch(`${API_ROOT}/core/users`).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw new Error('Error retreiving users.');
  });
}
