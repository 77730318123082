/* eslint-disable object-shorthand */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes, { number, string, bool } from 'prop-types';

import { Container, Form, Button } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from 'react-hook-form';
import { useSession } from '../queries/sessionQueries';
import { useAllUsers } from '../queries/usersQuery';
import ImageModal from '../modals/ImageModal';
import ImageSubmit from './ImageSubmit';

function ActivityForm(props) {
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    reset: resetFunc,
    formState: { errors },
  } = useForm();
  const { lineData, assetData, inspectionTestData, onSubmitActivity } = props;
  const { data: sessionData } = useSession();
  const { isLoading: isLoadingAllUsers, data: allUsers } =
    useAllUsers(sessionData);
  const findUser = (userObj) => {
    if (!sessionData) {
      return;
    }
    // eslint-disable-next-line consistent-return
    return userObj.id === sessionData?.userDetails?.split('@')[0].toUpperCase();
  };
  const targetIndex = allUsers?.findIndex(findUser) || 0;

  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lineOptions, setLineOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);
  const [milepostOptions, setMilepostOptions] = useState([]);
  const [jobOptions, setJobOptions] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(
    allUsers?.slice(targetIndex, targetIndex + 1) || ''
  );
  const [autofilledCrew, setAutofilledCrew] = useState(false);
  // console.log('all users: ', allUsers)
  // stateful values from useForm to trigger effects
  const routeSelection = watch('route');
  const typeSelection = watch('type');
  const nameSelection = watch('name');
  const locationSelection = watch('location');

  // this is necessary for the admin typeahead
  // which can only be instantiated with a default value
  // from .slicing the array of options.

  const lastNames = window.localStorage.getItem('lastNames');

  if (!autofilledCrew && lastNames !== 'undefined') {
    setValue('namesOfCrew', lastNames);
    setAutofilledCrew(true);
  }

  // increasingly detailed filters of asset and data to narrow down the user's choice
  const justThisLine = assetData?.filter(
    (asset) => asset.lineId === routeSelection
  );

  const justThisType = justThisLine?.filter(
    (asset) => asset.categoryId === typeSelection
  );

  const thisAsset = justThisType?.find((asset) => asset.id === nameSelection);

  const thisAssetInspectionTests = inspectionTestData?.filter(
    (test) => test.assetClass === thisAsset?.assetClass
  );

  const thisMilepost = thisAsset?.attributes?.find(
    (attribute) => attribute.name === 'TRMS MILEPOST'
  );

  const linesHavingAssets = useMemo(() => {
    if (assetData?.length && lineData?.length) {
      const index = assetData.reduce(
        (prev, current) => ({ ...prev, [current.lineId]: true }),
        {}
      );

      return lineData.filter(({ id }) => index[id]);
    }

    return [];
  }, [assetData, lineData]);

  useEffect(() => {
    // generate route dropdown options
    if (!linesHavingAssets || !assetData) {
      return;
    }

    const placeholder = (
      <option key="placeholder" value="">
        Select a line
      </option>
    );

    const alphabetizedLines = linesHavingAssets.sort((a, b) =>
      a.description.localeCompare(b.description)
    );

    const selectOptions = alphabetizedLines?.map((line) => (
      <option key={line.id} value={line.id}>
        {line.description}
      </option>
    ));

    selectOptions.unshift(placeholder);
    setLineOptions(selectOptions);
    // }
  }, [linesHavingAssets, assetData]);

  useEffect(() => {
    /* react to the route changing and generate type dropdown options
     * responsible for setting the 'type' field as well as resetting
     * fields below 'type' if the user changes their route selection
     */
    if (!routeSelection || routeSelection === 'Select a line') {
      setTypeOptions([]);
      setNameOptions([]);
      setMilepostOptions([]);
      setJobOptions([]);
      return;
    }

    const distinctCategories = justThisLine?.reduce((prev, current) => {
      if (!prev.includes(current.categoryId)) {
        prev.push(current.categoryId);
      }
      return prev;
    }, []);

    const alphabetizedCategories = distinctCategories?.sort((a, b) =>
      a.localeCompare(b)
    );

    const placeholder = (
      <option key="asset-placeholder" value="">
        Select an asset type
      </option>
    );
    const noneFound = <option>No types found</option>;

    const selectOptions = alphabetizedCategories?.map((category) => (
      <option key={category} value={category}>
        {category}
      </option>
    ));

    if (selectOptions?.length > 0) {
      selectOptions.unshift(placeholder);
      setTypeOptions(selectOptions);
    } else {
      setTypeOptions([noneFound]);
    }

    setNameOptions([]);
    setMilepostOptions([]);
  }, [routeSelection]);

  useEffect(() => {
    /* react to the type changing and generate name dropdown items
     * responsible for resetting the milepost field in the event
     * the user changes 'types'
     */
    if (!typeSelection) {
      return;
    }

    const placeholder = (
      <option key="name-placeholder" value="">
        Select an asset
      </option>
    );

    const selectOptions = justThisType.map((asset) => (
      <option key={asset.id} value={asset.id}>
        {asset.description}
      </option>
    ));
    selectOptions.unshift(placeholder);

    setNameOptions(selectOptions);
    setMilepostOptions([]);
  }, [routeSelection, typeSelection]);

  useEffect(() => {
    /* Reacts to the 'name' field changing, finds and sets milepost information as well as jobs.
     * responsible for setting the milepost field both in the form and in useForm's state
     * as well as generating the list of image components that correlate to the 'tests'
     * found at the intersection of 'asset.assetClass' and 'test.assetClass'
     */
    if (!nameSelection) {
      return;
    }

    const requiredImages = thisAssetInspectionTests.filter(
      (test) => test.allowFile && test.assetClass === thisAsset.assetClass
    );

    const inspectionTestWithActivities = thisAssetInspectionTests.find(
      (test) => test.description === 'WHAT DID YOU DO?'
    );
    const selectOptions =
      inspectionTestWithActivities?.checklist?.map((job) => (
        <option key={job.value} value={job.value}>
          {job.description}
        </option>
      )) || [];
    if (thisMilepost) {
      setMilepostOptions([
        <option value={thisMilepost.value}>{thisMilepost.value}</option>,
      ]);
      setJobOptions(selectOptions || []);
      setValue('location', thisMilepost.value);
      setImageList(requiredImages);
    } else {
      setMilepostOptions([
        <option key="milepost-placeholder" value={null}>
          A milepost for this asset was not found.
        </option>,
      ]);
      setValue('location', 'Milepost Not Found');
      setJobOptions(selectOptions);
      setImageList(requiredImages);
    }
  }, [nameSelection]);

  const resetForm = (namesOfCrew) => {
    setTypeOptions([]);
    setNameOptions([]);
    setMilepostOptions([]);
    setJobOptions([]);
    setImageList([]);

    resetFunc(
      {
        route: '',
        type: '',
        name: '',
        location: '',
        whatDidYouDo: '',
      },
      { keepIsValid: false, keepIsSubmitted: false }
    );

    window.localStorage.setItem('lastNames', namesOfCrew);
  };

  const onSubmit = async (data) => {
    const formData = data;
    formData.images = images;
    const now = new Date().toISOString();
    const activityPayload = {
      assetId: data.name,
      comments: data.namesOfCrew,
      workDoneBy: selectedUser && selectedUser[0] ? selectedUser[0].id : null,
      testResults: thisAssetInspectionTests.map((test) => {
        if (test?.checklist?.length > 0) {
          return {
            testElementId: test.id,
            qualitativeFinding: data.whatDidYouDo,
            datetimeTested: now,
          };
        }
        return {
          testElementId: test.id,
          qualitativeFinding: data.whatDidYouDo,
          datetimeTested: now,
        };
      }),
    };

    let preparedImages = [];
    if (formData?.images?.length && imageList?.length) {
      preparedImages = images.map((image, index) => {
        const preparedDataUrl = image.dataUrl.split(',')[1];
        const testElementId = imageList[index].id;

        return {
          dataUrl: preparedDataUrl,
          testElementId: testElementId,
        };
      });
    }
    // console.log('activity payload: ', activityPayload)
    onSubmitActivity(activityPayload, preparedImages);

    resetForm(data.namesOfCrew);
  };

  const handleReceivedImages = (image, index, location) => {
    const newImage = image[0];
    newImage.location = location;
    const newImages = images;
    // eslint-disable-next-line prefer-destructuring
    newImages[index] = image[0];
    setImages(newImages);
  };

  const isSubmitDisabled = jobOptions.length === 0;

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        {sessionData?.isAdmin && (
          <Form.Group className="mb-3">
            <Form.Label>User for this activity:</Form.Label>
            {!isLoadingAllUsers && (
              <Typeahead
                id="user-selection"
                labelKey={(option) => `${option.name}`}
                onChange={(e) => setSelectedUser(e)}
                defaultSelected={allUsers.slice(targetIndex, targetIndex + 1)}
                selected={selectedUser}
                placeholder="Select a user..."
                options={allUsers}
              />
            )}
          </Form.Group>
        )}
        <Form.Group className="mb-3" controlId="route">
          <Form.Label>Route</Form.Label>
          <Form.Select {...register('route', { required: true })}>
            {lineOptions}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="type">
          <Form.Label>Type</Form.Label>
          <Form.Select
            disabled={typeOptions.length === 0}
            control={control}
            {...register('type', { required: true })}
          >
            {typeOptions}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Select
            disabled={typeOptions.length === 0}
            control={control}
            {...register('name', { required: true })}
          >
            {nameOptions}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="location">
          <Form.Label>Location</Form.Label>
          <Form.Select
            value={locationSelection}
            disabled={milepostOptions.length === 0}
            control={control}
            {...register('location', { required: true })}
          >
            {milepostOptions}
          </Form.Select>
        </Form.Group>
        {jobOptions?.length > 0 && (
          <Form.Group className="mb-3" controlId="what-did-you-do">
            <Form.Label>What did you do</Form.Label>
            <Form.Select
              disabled={milepostOptions.length === 0}
              control={control}
              {...register('whatDidYouDo', { required: true })}
            >
              {jobOptions}
            </Form.Select>
          </Form.Group>
        )}

        {imageList.map((image, index) => (
          <ImageSubmit
            key={image.eamId}
            setLoading={setIsLoading}
            handleImage={handleReceivedImages}
            imageIndex={index}
            location={image.description}
          />
        ))}
        <Form.Group className="mb-3" controlId="crew-names">
          <Form.Label>Full names of crew</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            {...register('namesOfCrew', {
              required: 'Crew names are required.',
            })}
            isInvalid={!!errors.namesOfCrew}
            feedback="Error message"
            feedbacktype="invalid"
          />
          <Form.Control.Feedback type="invalid">
            {errors?.namesOfCrew?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <div className="text-center">
          <Button
            disabled={isSubmitDisabled}
            onClick={handleSubmit(onSubmit)}
            style={{ color: 'white' }}
            variant={isSubmitDisabled ? 'secondary' : 'primary'}
          >
            Submit Activity
          </Button>
        </div>
      </Form>
      {isLoading && <ImageModal show={isLoading} />}
      <ImageModal show={!assetData} />
    </Container>
  );
}

export default ActivityForm;

ActivityForm.defaultProps = {
  lineData: [],
  assetData: [],
  inspectionTestData: [],
};

ActivityForm.propTypes = {
  onSubmitActivity: PropTypes.func.isRequired,
  lineData: PropTypes.arrayOf(
    PropTypes.shape({
      eamId: number,
      id: string,
      description: string,
    })
  ),
  assetData: PropTypes.arrayOf(
    PropTypes.shape({
      activities: PropTypes.arrayOf(
        PropTypes.shape({
          assetId: string,
          comments: string,
          id: number,
          jobType: string,
          taskId: string,
          testResults: PropTypes.arrayOf(
            PropTypes.shape({
              comments: string,
              datetimeTested: string,
              id: number,
              imagePath: string,
              qualitativeFinding: string,
              testElementId: string,
              workOrderId: number,
            })
          ),
          userId: string,
        })
      ),
      assetClass: string,
      assetNumber: string,
      attributes: PropTypes.arrayOf(
        PropTypes.shape({
          active: string,
          assetId: string,
          dateValue: string,
          displayOrder: number,
          id: number,
          name: string,
          numericValue: number,
          subsystem: string,
          textValue: string,
          value: string,
        })
      ),
      categoryId: string,
      description: string,
      displayName: string,
      eamId: number,
      id: string,
      lastClear: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lastPass: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lastPreSalt: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lastSalt: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lineId: string,
      milepost: string,
      serialNumber: string,
      title: string,
      type: string,
    })
  ),
  inspectionTestData: PropTypes.arrayOf(
    PropTypes.shape({
      allowFile: bool,
      assetClass: string,
      checklist: PropTypes.arrayOf(
        PropTypes.shape({
          id: number,
          checklistType: string,
          value: string,
          description: string,
        })
      ),
      checklistType: string,
      description: string,
      displayOrder: number,
      eamId: number,
      id: string,
      requireFile: bool,
      testId: string,
    })
  ),
};
