import React, { useMemo } from 'react';
import PropTypes, { number, string, func } from 'prop-types';

import { Card, Row, Col, Form, ToggleButton } from 'react-bootstrap';

function ActivityGridFilters(props) {
  const {
    lines,
    assets,
    handleLineFilter,
    handleAssetFilter,
    filteredLines,
    filteredAssets,
    currentVisibleAssets,
    statusFilters,
    handleOkAlertFilter,
  } = props;
  const distinctAssets = assets?.reduce((prev, current) => {
    if (!prev.includes(current.categoryId)) {
      prev.push(current.categoryId);
    }
    return prev;
  }, []);

  const linesHavingAssets = useMemo(() => {
    if (assets?.length && lines?.length) {
      const index = assets.reduce(
        (prev, current) => ({ ...prev, [current.lineId]: true }),
        {}
      );

      return lines.filter(({ id }) => index[id]);
    }

    return [];
  }, [assets, lines]);

  /**
   * Relevant lines are those that contain one of the asset
   * categories we are currently filtering on (e.g., `STATION`)
   */
  const relevantLines = useMemo(() => {
    let result = linesHavingAssets;

    if (assets?.length && filteredAssets?.length) {
      const relevantLineIds = assets
        .filter((a) => filteredAssets.includes(a.categoryId))
        .reduce((prev, current) => ({ ...prev, [current.lineId]: true }), {});

      result = linesHavingAssets.filter((line) => relevantLineIds[line.id]);
    }

    return result;
  }, [assets, filteredAssets, linesHavingAssets]);

  return (
    <Card>
      <Card.Header className="bg-secondary text-white text-center fw-bold">
        Filters
      </Card.Header>
      <Card.Body>
        <Form.Label>Asset Type</Form.Label>
        <Row>
          {distinctAssets?.map((asset) => (
            <Col md={6} key={`${asset}`}>
              <ToggleButton
                disabled={!currentVisibleAssets.includes(asset)}
                className="d-block mb-3"
                id={asset}
                type="checkbox"
                variant="outline-secondary"
                size="sm"
                checked={filteredAssets.includes(asset)}
                onClick={() => handleAssetFilter(asset)}
                value={asset}
              >
                {asset}
              </ToggleButton>
            </Col>
          ))}
        </Row>

        <hr />

        <Form.Label>Route</Form.Label>
        <Row>
          {relevantLines?.map((line) => (
            <Col md={6} key={line.eamId}>
              <ToggleButton
                className="d-block mb-3"
                id={line.id}
                type="checkbox"
                variant="outline-secondary"
                size="sm"
                checked={filteredLines.includes(line.id)}
                onClick={() => handleLineFilter(line.id)}
                value={line.id}
              >
                {line.description}
              </ToggleButton>
            </Col>
          ))}
        </Row>
        <Form.Label>Status</Form.Label>
        <Row>
          <Col md={6}>
            <ToggleButton
              className="d-block mb-3"
              id="ok"
              type="checkbox"
              variant="outline-secondary"
              size="sm"
              value="OK"
              checked={statusFilters.includes('ok')}
              onClick={() => handleOkAlertFilter('ok')}
            >
              OK
            </ToggleButton>
          </Col>
          <Col md={6}>
            <ToggleButton
              className="d-block mb-3"
              id="alert"
              type="checkbox"
              variant="outline-secondary"
              size="sm"
              value="Alert"
              checked={statusFilters.includes('alert')}
              onClick={() => handleOkAlertFilter('alert')}
            >
              Alert
            </ToggleButton>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

ActivityGridFilters.defaultProps = {
  lines: [],
  assets: [],
  filteredLines: [],
  filteredAssets: [],
  currentVisibleAssets: [],
  handleLineFilter: func,
  handleAssetFilter: func,
  handleOkAlertFilter: func,
  statusFilters: [],
};

ActivityGridFilters.propTypes = {
  lines: PropTypes.arrayOf(
    PropTypes.shape({
      eamId: number,
      id: string,
      description: string,
    })
  ),

  assets: PropTypes.arrayOf(
    PropTypes.shape({
      eamId: number,
      id: string,
      lineId: string,
      displayName: string,
      description: string,
      title: string,
    })
  ),
  handleOkAlertFilter: PropTypes.func,
  handleLineFilter: PropTypes.func,
  handleAssetFilter: PropTypes.func,
  filteredLines: PropTypes.arrayOf(PropTypes.string),
  filteredAssets: PropTypes.arrayOf(PropTypes.string),
  currentVisibleAssets: PropTypes.arrayOf(PropTypes.string),
  statusFilters: PropTypes.arrayOf(PropTypes.string),
};

export default ActivityGridFilters;
