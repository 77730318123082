import React from 'react';

function AppLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="keolis-logo"
      viewBox="0 0 350.69 90.26"
    >
      <path d="M0 0h350.69v90.26H0z" fill="none" />
      <path
        d="M304.85 41.33h-24.11a4.63 4.63 0 0 1 0-9.26h27.31l2.71-7.6h-30a12.23 12.23 0 0 0 0 24.46h24.11a4.63 4.63 0 0 1 0 9.26h-33.09l-2.71 7.6h35.78a12.23 12.23 0 0 0 0-24.46zm-85.89 12.46v-29.3h-7.56v29.3a12 12 0 0 0 12 12h18.89l2.71-7.6h-21.6a4.4 4.4 0 0 1-4.44-4.4z"
        fill="#776e65"
        fillRule="evenodd"
      />
      <path d="M251.24 24.46h7.6v41.33h-7.6z" fill="#776e65" />
      <path
        d="M189.4 24.49h-25.59a12 12 0 0 0-12 12v17.3a12 12 0 0 0 12 12h25.59a12 12 0 0 0 12-12v-17.3a12 12 0 0 0-12-12zm4.4 29.32a4.4 4.4 0 0 1-4.4 4.4h-25.59a4.4 4.4 0 0 1-4.4-4.4V36.49a4.4 4.4 0 0 1 4.4-4.4h25.59a4.4 4.4 0 0 1 4.4 4.4zm-63.79-29.32H105.4a12 12 0 0 0-12 12v17.3a12 12 0 0 0 12 12h30.81l2.71-7.6H105.4a4.4 4.4 0 0 1-4.4-4.4v-4.86h41V36.49a12 12 0 0 0-11.99-12zm4.4 16.86h-33.36v-4.86a4.4 4.4 0 0 1 4.4-4.4h24.56a4.4 4.4 0 0 1 4.39 4.4z"
        fill="#00abc9"
        fillRule="evenodd"
      />
      <path d="M33.61 24.46h7.6v41.33h-7.6z" fill="#00abc9" />
      <path
        d="M52.61 43.35l35.7-12.51-2.64-7.35-35.63 12.87a9.36 9.36 0 0 0 0 17.62l35.63 12.87 2.64-7.39-35.7-12.51a1.9 1.9 0 0 1 0-3.56z"
        fill="#00abc9"
        fillRule="evenodd"
      />
    </svg>
  );
}

export default AppLogo;
