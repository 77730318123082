import { useQuery, useQueryClient } from '@tanstack/react-query';
import { intersection } from 'lodash';
import getUser, { getUserExistsStatus } from '../api/user';

export function useSession() {
  return useQuery(
    ['session'],
    async () => {
      const response = await getUser();
      let name = '';
      let userName = '';
      let isAuthenticated = false;
      let isAdmin = false;
      const lastRefreshTimestamp = Date.now();

      if (response.clientPrincipal) {
        const { userId, userDetails, userRoles, claims } =
          response.clientPrincipal;

        if (
          userRoles &&
          // compare roles provided by AD identity provider to the roles
          // the we consider admin roles. If any match the user is an admin.
          intersection(userRoles, ['ad_snapp_admin', 'ad_bowst_remote_access'])
            .length
        ) {
          isAdmin = true;
        }

        if (userRoles && userRoles.includes('authenticated')) {
          isAuthenticated = true;
          const nameClaim = claims.find(({ typ }) => typ === 'name');
          const userNameClaim = claims.find(
            ({ typ }) => typ === 'preferred_username'
          );

          if (nameClaim) {
            name = nameClaim.val;
          }

          if (userNameClaim) {
            userName = userNameClaim.val;
          }

          let existsInEamDb = true;

          // Now check to see if the user exists in the EAM database
          const userExistsStatus = await getUserExistsStatus();
          if (!userExistsStatus.unknownError && !userExistsStatus.userExists) {
            existsInEamDb = false;
          }

          return {
            isAuthenticated,
            isAdmin,
            userId,
            userDetails,
            userRoles,
            claims,
            name,
            userName,
            existsInEamDb,
            lastRefreshTimestamp,
          };
        }
      }

      return {
        isAuthenticated,
        isAdmin,
        name,
        userName,
        lastRefreshTimestamp,
      };
    },
    {
      cacheTime: Infinity,
      staleTime: 60 * 1000,
      refetchInterval: 60 * 1000,
      refetchOnMount: 'always',
      enabled: true,
    }
  );
}

export function endSession() {
  const queryClient = useQueryClient();
  queryClient.resetQueries({ queryKey: ['session'], exact: true });
}
