/* eslint-disable object-shorthand */
import React, { useState, useEffect, useMemo } from 'react';
import PropTypes, { func, number, string, bool } from 'prop-types';

import {
  Container,
  Form,
  Button,
  Col,
  Row,
  Stack,
  Modal,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useForm } from 'react-hook-form';
import { useSession } from '../queries/sessionQueries';
import { useAllUsers } from '../queries/usersQuery';

import ImageModal from './ImageModal';
import ImageSubmit from '../components/ImageSubmit';
import { updateActivity, saveImage } from '../api/index';
import {
  getDateStringForInput,
  getDateFromInputString,
} from '../shared/helpers';

function ActivityEditModal(props) {
  const {
    handleClose,
    assetLine,
    assetType,
    selectedActivity,
    lineData,
    assetData,
    inspectionTestData,
    refetchActivities,
  } = props;
  const {
    handleSubmit,
    control,
    register,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const { data: sessionData } = useSession();
  const { isLoading: isLoadingAllUsers, data: allUsers } =
    useAllUsers(sessionData);
  const findSelectedActivityUser = (userObj) =>
    userObj.id === selectedActivity?.workDoneBy;
  const targetIndex = allUsers?.findIndex(findSelectedActivityUser);

  const [images, setImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lineOptions, setLineOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [nameOptions, setNameOptions] = useState([]);

  const [milepostOptions, setMilepostOptions] = useState([]);
  const [jobOptions, setJobOptions] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const [selectedUser, setSelectedUser] = useState(
    allUsers?.slice(targetIndex, targetIndex + 1) || ''
  );
  // this is necessary for the admin typeahead
  // which can only be instantiated with a default value
  // from .slicing the array of options.

  const linesHavingAssets = useMemo(() => {
    if (assetData?.length && lineData?.length) {
      const index = assetData.reduce(
        (prev, current) => ({ ...prev, [current.lineId]: true }),
        {}
      );

      return lineData.filter(({ id }) => index[id]);
    }

    return [];
  }, [assetData, lineData]);

  const generateLineOptions = () => {
    const alphabetizedLines = linesHavingAssets?.sort((a, b) =>
      a.description.localeCompare(b.description)
    );
    const placeholder = <option key="line-placeholder">Select a line</option>;

    let selectOptions = [];
    if (alphabetizedLines?.length) {
      selectOptions = alphabetizedLines.map((line) => (
        <option key={line.id} value={line.id}>
          {line.description}
        </option>
      ));
    }
    selectOptions.unshift(placeholder);
    return selectOptions;
  };

  const generateTypeOptions = (line) => {
    const distinctTypes = assetData.filter((asset) => asset.lineId === line);

    const distinctCategories = distinctTypes.reduce((prev, current) => {
      if (!prev.includes(current.categoryId)) {
        prev.push(current.categoryId);
      }
      return prev;
    }, []);

    const alphabetizedCategories = distinctCategories?.sort((a, b) =>
      a.localeCompare(b)
    );

    const placeholder = (
      <option key="type-placeholder" value="">
        {' '}
        Select an asset type{' '}
      </option>
    );
    const selectOptions = alphabetizedCategories?.map((category) => (
      <option key={category} value={category}>
        {category}
      </option>
    ));

    if (selectOptions?.length) {
      selectOptions.unshift(placeholder);
      return selectOptions;
    }
    return [<option>No types found</option>];
  };

  const generateNameOptions = (line, type) => {
    const justThisLine = assetData?.filter((asset) => asset.lineId === line);

    const justThisType = justThisLine?.filter(
      (asset) => asset.categoryId === type
    );

    const placeholder = (
      <option key="name-placeholder" value="">
        Select an asset
      </option>
    );

    const selectOptions = justThisType.map((asset) => (
      <option key={asset.id} value={asset.id}>
        {asset.description}
      </option>
    ));
    selectOptions.unshift(placeholder);

    setMilepostOptions([]);
    return selectOptions;
  };

  const generateLocationAndActivity = (name) => {
    const thisAsset = assetData.find((asset) => asset.id === name);

    const thisAssetInspectionTests = inspectionTestData?.filter(
      (test) => test.assetClass === thisAsset?.assetClass
    );

    const whatDidYouDo = thisAssetInspectionTests.find(
      (test) => test.description === 'WHAT DID YOU DO?'
    );

    const requiredImages = thisAssetInspectionTests.filter(
      (test) => test.allowFile && test.assetClass === thisAsset?.assetClass
    );

    // this will trigger the existing activity's images
    // to go into the image components
    if (selectedActivity.assetId === thisAsset?.id) {
      requiredImages.forEach((image) => {
        // const incomingUserImage = selectedActivity.testResults.find((thing) => thing.testElementId === image.id)
        // image.imagePath = `${API_ROOT}${incomingUserImage.imagePath}` || false
        // eslint-disable-next-line no-param-reassign
        image.workOrderId = selectedActivity.id;
      });
    }

    console.log(
      'required images needs work order id and test element id: ',
      requiredImages
    );
    const thisMilepost = thisAsset?.attributes?.find(
      (attribute) => attribute.name === 'TRMS MILEPOST'
    );

    if (thisMilepost) {
      setMilepostOptions([
        <option value={thisMilepost.value}>{thisMilepost.value}</option>,
      ]);
      setImageList(requiredImages);
      setValue('location', thisMilepost.value);
    } else {
      setMilepostOptions([
        <option key="milepost-placeholder" value={null}>
          A milepost for this asset was not found.
        </option>,
      ]);
      setImageList(requiredImages);
    }

    const selectOptions =
      whatDidYouDo?.checklist?.map((job) => (
        <option value={job.value}> {job.description}</option>
      )) || [];

    return selectOptions;
  };

  useEffect(() => {
    if (selectedActivity && assetLine && assetType) {
      const commentField =
        selectedActivity.comments || 'Names of crew not found';

      reset({
        route: assetLine,
        type: assetType,
        name: selectedActivity.assetId,
        whatDidYouDo: selectedActivity.testResults[0].qualitativeFinding,
        namesOfCrew: commentField,
        datetime: getDateStringForInput(
          selectedActivity?.testResults?.[0]?.datetimeTested || new Date()
        ),
      });

      setLineOptions(generateLineOptions());
      setTypeOptions(generateTypeOptions(assetLine));
      setNameOptions(generateNameOptions(assetLine, assetType));
      setJobOptions(generateLocationAndActivity(selectedActivity.assetId));
    } else {
      // user clicks away, zeroing out the state of the form
      reset({
        route: '',
        type: '',
        name: '',
        whatDidYouDo: '',
        namesOfCrew: '',
        datetime: getDateStringForInput(new Date()),
      });
    }
  }, [selectedActivity]);

  const routeSelection = watch('route');
  const typeSelection = watch('type');
  const nameSelection = watch('name');
  const locationSelection = watch('location');
  const activity = watch('whatDidYouDo');
  const crewNames = watch('namesOfCrew');

  useEffect(() => {
    /* react to the route changing and generate type dropdown options
     * responsible for setting the 'type' field as well as resetting
     * fields below 'type' if the user changes their route selection
     */

    if (routeSelection === 'Select a line') {
      setTypeOptions([]);
      setNameOptions([]);
      setMilepostOptions([]);
      setJobOptions([]);
      return;
    }

    if (routeSelection) {
      setTypeOptions(generateTypeOptions(routeSelection));
      setNameOptions([]);
      setMilepostOptions([]);
    }
  }, [routeSelection]);

  useEffect(() => {
    /* react to the type changing and generate name dropdown items
     * responsible for resetting the milepost field in the event
     * the user changes 'types'
     */
    if (!typeSelection) {
      return;
    }
    setNameOptions(generateNameOptions(routeSelection, typeSelection));
  }, [typeSelection]);

  useEffect(() => {
    /* Reacts to the 'name' field changing, finds and sets milepost information as well as jobs.
     * responsible for setting the milepost field both in the form and in useForm's state
     * as well as generating the list of image components that correlate to the 'tests'
     * found at the intersection of 'asset.assetClass' and 'test.assetClass'
     */
    if (!nameSelection) {
      return;
    }

    setJobOptions(generateLocationAndActivity(nameSelection) || []);
  }, [nameSelection]);

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setIsSubmitModalOpen(true);

    const formData = data;

    const thisAsset = assetData.find((asset) => asset.id === formData.name);

    const thisAssetInspectionTests = inspectionTestData.filter(
      (test) => test.assetClass === thisAsset.assetClass
    );
    formData.images = images;
    const payload = {
      assetId: data.name,
      comments: data.namesOfCrew,
      workDoneBy: selectedUser[0].id,
      note: data.changeComment,
      testResults: thisAssetInspectionTests.map((test) => ({
        testElementId: test.id,
        qualitativeFinding: data.whatDidYouDo,
        datetimeTested: getDateFromInputString(formData.datetime).toISOString(),
      })),
    };

    try {
      await updateActivity(payload, selectedActivity.id);

      if (images?.length) {
        const preparedImages = images.map((image, index) => {
          const preparedDataUrl = image.dataUrl.split(',')[1];
          const testElementId = imageList[index].id;

          return {
            dataUrl: preparedDataUrl,
            testElementId: testElementId,
            workOrderId: selectedActivity.id,
          };
        });

        // Upload the images serially (not in parallel)
        for (let i = 0; i < preparedImages.length; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          await saveImage(preparedImages[i]);
        }
      }
    } catch (e) {
      setSubmitError(e.message || 'Unknown error');
    } finally {
      setIsSubmitting(false);
    }

    refetchActivities();
  };

  const handleReceivedImages = (image, index, location) => {
    const newImage = image[0];
    newImage.location = location;
    const newImages = images;
    // eslint-disable-next-line prefer-destructuring
    newImages[index] = image[0];
    setImages(newImages);
  };
  return (
    <Container className="edit-modal">
      <Form
        onSubmit={handleSubmit(onSubmit)}
        className={isSubmitModalOpen ? 'opacity-25' : ''}
      >
        <Row>
          <Col>
            {sessionData?.isAdmin && (
              <Form.Group className="mb-3">
                <Form.Label>User for this activity:</Form.Label>
                {!isLoadingAllUsers && (
                  <Typeahead
                    id="user-selection"
                    labelKey={(option) => `${option.name}`}
                    onChange={(e) => setSelectedUser(e)}
                    defaultSelected={allUsers.slice(
                      targetIndex,
                      targetIndex + 1
                    )}
                    selected={selectedUser}
                    placeholder="Select a user..."
                    options={allUsers}
                  />
                )}
              </Form.Group>
            )}
            <Form.Group className="mb-3" controlId="route">
              <Form.Label>Date and Time</Form.Label>
              <Form.Control
                type="datetime-local"
                max={getDateStringForInput(new Date())}
                {...register('datetime', {
                  required: true,
                  validate: (v) => {
                    const date = getDateFromInputString(v);
                    return date <= new Date()
                      ? true
                      : 'Cannot be a future date';
                  },
                })}
                isInvalid={!!errors.datetime}
                feedback="Error message"
                feedbackType="invalid"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.datetime?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="route">
              <Form.Label>Route</Form.Label>
              <Form.Select
                value={routeSelection}
                {...register('route', { required: true })}
              >
                {lineOptions}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="type">
              <Form.Label>Type</Form.Label>
              <Form.Select
                value={typeSelection}
                disabled={typeOptions.length === 0}
                control={control}
                {...register('type', { required: true })}
              >
                {typeOptions}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Name</Form.Label>
              <Form.Select
                value={nameSelection}
                disabled={typeOptions.length === 0}
                control={control}
                {...register('name', { required: true })}
              >
                {nameOptions}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="location">
              <Form.Label>Location</Form.Label>
              <Form.Select
                value={locationSelection}
                disabled={milepostOptions.length === 0}
                control={control}
                {...register('location', { required: true })}
              >
                {milepostOptions}
              </Form.Select>
            </Form.Group>
          </Col>

          <Col>
            {jobOptions?.length > 0 && (
              <Form.Group className="mb-3" controlId="what-did-you-do">
                <Form.Label>What did you do</Form.Label>
                <Form.Select
                  value={activity}
                  disabled={milepostOptions.length === 0}
                  control={control}
                  {...register('whatDidYouDo', { required: true })}
                >
                  {jobOptions}
                </Form.Select>
              </Form.Group>
            )}

            <Form.Group className="mb-3" controlId="crew-names">
              <Form.Label>Full names of crew</Form.Label>
              <Form.Control
                value={crewNames}
                as="textarea"
                rows={3}
                {...register('namesOfCrew', {
                  required: 'Crew names are required.',
                })}
                isInvalid={!!errors.namesOfCrew}
                feedback="Error message"
                feedbackType="invalid"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.namesOfCrew?.message}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="change-comment">
              <Form.Label> Change Comment </Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                {...register('changeComment', {
                  required: 'Change Comment is required.',
                })}
                isInvalid={!!errors.changeComment}
                feedback="Error message"
                feedbackType="invalid"
              />
              <Form.Control.Feedback type="invalid">
                {errors?.changeComment?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          {imageList.map((image, index) => (
            <Col sm={6}>
              <ImageSubmit
                key={image.eamId}
                setLoading={setIsLoading}
                handleImage={handleReceivedImages}
                imageIndex={index}
                location={image.description}
                workOrderId={image.workOrderId}
                testElementId={image.id}
              />
            </Col>
          ))}
        </Row>
        <div className="text-center">
          <Stack
            direction="horizontal"
            className="justify-content-center"
            gap={5}
          >
            <Button
              onClick={() => handleClose()}
              variant="secondary"
              margin-right="40px"
            >
              Cancel
            </Button>
            <Button
              disabled={jobOptions.length === 0}
              onClick={handleSubmit(onSubmit)}
              variant="secondary"
            >
              Submit Changes
            </Button>
          </Stack>
        </div>
      </Form>
      {isLoading && <ImageModal show={isLoading} />}

      <Modal show={isSubmitModalOpen} centered>
        <Modal.Body>
          {isSubmitting && <p>Saving...</p>}
          {!!submitError && (
            <p>
              A server or network error prevent the application from saving your
              changes.
            </p>
          )}
          {!isSubmitting && !submitError && (
            <p>Your changes have been saved.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isSubmitting}
            onClick={() => {
              // If there was no error, assume all went great and
              // close the main edit modal
              if (!submitError) {
                handleClose();
              }

              // Reset the submission status state and give the user
              // a chance to try again if they want
              setIsSubmitModalOpen(false);
              setIsSubmitting(false);
              setSubmitError(null);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

ActivityEditModal.defaultProps = {
  handleClose: func,
  lineData: [],
  assetData: [],
  inspectionTestData: [],
  selectedActivity: {},
  assetLine: '',
  assetType: '',
};

ActivityEditModal.propTypes = {
  handleClose: PropTypes.func,
  refetchActivities: PropTypes.func.isRequired,
  lineData: PropTypes.arrayOf(
    PropTypes.shape({
      eamId: number,
      id: string,
      description: string,
    })
  ),
  assetData: PropTypes.arrayOf(
    PropTypes.shape({
      activities: PropTypes.arrayOf(
        PropTypes.shape({
          assetId: string,
          comments: string,
          id: number,
          jobType: string,
          taskId: string,
          testResults: PropTypes.arrayOf(
            PropTypes.shape({
              comments: string,
              datetimeTested: string,
              id: number,
              imagePath: string,
              qualitativeFinding: string,
              testElementId: string,
              workOrderId: number,
            })
          ),
          userId: string,
        })
      ),
      assetClass: string,
      assetNumber: string,
      attributes: PropTypes.arrayOf(
        PropTypes.shape({
          active: string,
          assetId: string,
          dateValue: string,
          displayOrder: number,
          id: number,
          name: string,
          numericValue: number,
          subsystem: string,
          textValue: string,
          value: string,
        })
      ),
      categoryId: string,
      description: string,
      displayName: string,
      eamId: number,
      id: string,
      lastClear: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lastPass: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lastPreSalt: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lastSalt: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lineId: string,
      milepost: string,
      serialNumber: string,
      title: string,
      type: string,
    })
  ),
  inspectionTestData: PropTypes.arrayOf(
    PropTypes.shape({
      allowFile: bool,
      assetClass: string,
      checklist: PropTypes.arrayOf(
        PropTypes.shape({
          id: number,
          checklistType: string,
          value: string,
          description: string,
        })
      ),
      checklistType: string,
      description: string,
      displayOrder: number,
      eamId: number,
      id: string,
      requireFile: bool,
      testId: string,
    })
  ),
  selectedActivity: PropTypes.shape({
    assetId: string,
    comments: string,
    id: number,
    jobType: string,
    taskId: string,
    testResults: PropTypes.arrayOf(
      PropTypes.shape({
        coments: string,
        datetimeTested: string,
        ind: number,
        imagePath: string,
        qualitativeFinding: string,
        testElementId: string,
        workOrderId: number,
      })
    ),
    userId: string,
    workDoneBy: string,
  }),
  assetLine: string,
  assetType: string,
};

export default ActivityEditModal;
