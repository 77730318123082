/*eslint-disable */
import formatDate from 'date-fns/format';
import parseDate from 'date-fns/parse';
import { findLast } from 'lodash';

export const DATE_RENDER_FORMAT = 'MMM d p';

export function titleCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

export function getBadgeColor(statusRule, lastActivity) {
  /* Generates the string value for activity grid badges
   * based on the time rule and activity's timestamp.
   * Will return 'secondary' - a grey badge in the event of
   * no activity, or a status rule of 0 hours
   */

  // danger when there is NO or an OLD activity and ONLY if the slider has a value
  // if the status slider is at zero, return a grey badge that will count towards neither
  // 'ok' or 'alert'
  if (statusRule === 0) {
    return 'secondary';
  }

  // If there is no activity found, automatic danger
  if (!lastActivity) {
    return 'danger';
  }

  const now = new Date().getTime();
  const maxTime = statusRule * 3600;
  const warningCutoff = maxTime * 0.8;

  const elapsedTime =
    (now - new Date(lastActivity.testResults[0].datetimeTested).getTime()) /
    1000;

  if (elapsedTime < warningCutoff) {
    return 'success';
  }

  if (elapsedTime >= warningCutoff && elapsedTime < maxTime) {
    return 'warning';
  }

  if (elapsedTime >= maxTime) {
    return 'danger';
  }

  return 'danger';
}

const DATE_INPUT_FORMAT = "yyyy-MM-dd'T'HH:mm";
/**
 * Converts a date object or ISO-formatted date string to a string
 * date with the format expected by datetime-local type HTML inputs.
 *
 * @param {string|Date} date
 * @returns string
 */
export function getDateStringForInput(date) {
  let dateObj;
  if (typeof date === 'string') {
    dateObj = new Date(date);
  } else {
    dateObj = date;
  }

  return formatDate(dateObj, DATE_INPUT_FORMAT);
}

/**
 * Converts a date string value like those used by the datetime-local
 * type HTML inputs to a date object.
 * 
 * @param {string} date
 * @returns Date
 */
export function getDateFromInputString(dateString) {
  return parseDate(dateString, DATE_INPUT_FORMAT, new Date());
}

export function generateActivityCSV(activities) {
  const columns = [
    'Submitted By',
    'Logged In User',
    'Employee ID',
    'Location',
    'Photos',
    'Time',
    'Type',
  ];

const rows = activities.map((activity)=> {
  const { datetimeTested, qualitativeFinding } = activity.testResults[0] || 'null'
  const oneRow = [
    '',
    activity.userId,
    '',
    activity.assetId,
    '',
    datetimeTested ? new Date(datetimeTested) : ' ',
    '',
    qualitativeFinding,
  ]
  return oneRow
})

  rows.unshift(columns)
  return rows
};

export function generateAssetCSV(activities, assets) {
  /* this needs to do much of what the asset prep function does
   * but skip over things like badge color and alert/ok states
   * find assoc. activities
   * find last of each and most recent
   * as date iso values.
   * However this method of preparing the assets is different enough
   * (and may result in a further filtering of assets)
   * that it exists as a separate function.
  */

  const freshAssets = [...assets]
  const preparedAssets = freshAssets.map((asset) => {

    const associatedActivities = activities?.filter(
      (activity) => activity.assetId === asset.id && activity.id !== 189
    );

    if (associatedActivities.length >= 1) {
      const lastActivity = associatedActivities[associatedActivities.length - 1]
      const lastActivityTime = lastActivity.testResults[0].datetimeTested
      asset.lastActivityTime = lastActivityTime ? new Date(lastActivityTime) : false
      asset.lastActivityType = lastActivity.testResults[0].qualitativeFinding
    }

    const lastPreSalt =
    findLast(
      associatedActivities,
      (activity) =>
        activity.testResults[0]?.qualitativeFinding === 'PRE-SALT'
    ) || false;


    asset.lastPreSaltTime = lastPreSalt? new Date(lastPreSalt.testResults[0]?.datetimeTested) : false

    const lastPass =
    findLast(
      associatedActivities,
      (activity) => activity.testResults[0]?.qualitativeFinding === 'PASS'
    ) || false;

    asset.lastPassTime = lastPass? new Date(lastPass.testResults[0]?.datetimeTested) : false

    const lastClearSalt =
    findLast(
      associatedActivities,
      (activity) =>
        activity.testResults[0]?.qualitativeFinding === 'CLEAR & SALTED'
    ) || false;



  const lastClear =
    findLast(
      associatedActivities,
      (activity) =>
        activity.testResults[0]?.qualitativeFinding === 'CLEAR'
    ) || false;

    // const lastClearTime = lastClear?.testResults[0].datetimeTested || false

  const lastSalt =
    findLast(
      associatedActivities,
      (activity) =>
        activity.testResults[0]?.qualitativeFinding === 'SALTED'
    ) || false;

    // const lastSaltTime = lastSalt?.testResults[0].datetimeTested || false

    // must now determine if the last clear + salt is before or after the last 
    // individual clear and salt jobs.

    if (lastClearSalt && (lastClear || lastSalt)) {
      const lastClearSaltTime = new Date(
        lastClearSalt.testResults[0].datetimeTested
      );
      // if there is a clear and salt in the asset's history
      // but also a clear or a salt, we need to see which is newer
      // so as to properly color the table badges
      if (lastClear) {
        const lastClearTime =  lastClear? new Date(
          lastClear?.testResults[0].datetimeTested
        ) : false
        if (lastClearSaltTime.getTime() > lastClearTime.getTime()) {
          // last salt and clear is newer and takes priority
          asset.lastClearTime = lastClearSaltTime;
        } else {
          // last clear job is newer and takes priority
          asset.lastClearTime = lastClearTime;
        }
      }

      if (lastSalt) {
        const lastSaltTime = lastSalt? new Date(
          lastSalt.testResults[0].datetimeTested
        ) : false
        if (lastClearSaltTime.getTime() > lastSaltTime.getTime()) {
          // last salt and clear is newer and takes priority
          asset.lastSaltTime = lastClearSaltTime
        } else {
          // last salt time is newer and takes priority
          asset.lastSaltTime = lastSaltTime
        }
      } 
    } else {
      // there are no individual clear or salt jobs, and the previous if
      // never hit, so assign the most recent of each to the 
      // last clear + salt
      const lastClearSaltTime =  lastClearSalt? new Date(
        lastClearSalt.testResults[0].datetimeTested
      ) : false
      asset.lastSaltTime = lastClearSaltTime
      asset.lastClearTime = lastClearSaltTime
    }

    if (!lastClearSalt) {
      asset.lastClearTime =  lastClear? new Date(
        lastClear?.testResults[0].datetimeTested
      ) : false
      asset.lastSaltTime = lastSalt? new Date(
        lastSalt.testResults[0].datetimeTested
      ) : false
      
    }



    return asset
  })

  const columns = [
    'Clear Last Time',
    'Division',
    'Asset ID',
    'Last Activity Time',
    'Last Activity Type',
    'Location',
    'Name',
    'Pass Last Time',
    'Pre Salt Last Time',
    'Route',
    'Salt Last Time',
    'Type',
  ]

  const rows = preparedAssets.map((asset) => {
    const oneRow = [
      asset.lastClearTime || ' ',
      asset.disision || ' ',
      asset.id,
      asset.lastActivityTime || ' ',
      asset.lastActivityType || ' ',
      asset.description,
      asset.displayName,
      asset.lastPassTime || ' ',
      asset.lastPreSaltTime || ' ',
      asset.displayName,
      asset.lastSaltTime || ' ',
      asset.assetClass,
    ]

    return oneRow
  })

  rows.unshift(columns)
  return rows
}
