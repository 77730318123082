import React from 'react';
import { Container, Button, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOutAlt,
  faCircleExclamation,
  faCircleArrowUp,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useSession } from '../queries/sessionQueries';
import DownloadDataModal from '../modals/DownloadDataModal';

import AppLogo from './AppLogo';

function AppHeader({ onSyncIconClick, syncStatus, isOnline }) {
  const { data: sessionData } = useSession();

  let syncStatusIcon = faCircleCheck;
  let syncStatusColor = '#00abc9';

  if (syncStatus.state === 'syncing') {
    syncStatusIcon = faCircleArrowUp;
  } else if (syncStatus.error || !isOnline || !sessionData?.isAuthenticated) {
    syncStatusIcon = faCircleExclamation;
    syncStatusColor = '#fe4a4a';
  }

  return (
    <header id="AppHeader">
      <Container fluid>
        <div className={sessionData?.isAdmin ? 'd-md-none' : ''}>
          <Stack direction="horizontal" className="justify-content-between">
            <div>
              <div className="user-name">
                <div className="last-name">{sessionData?.name || ''}</div>
                <div className="first-name">{sessionData?.userName || ''}</div>
              </div>
            </div>

            <AppLogo />

            {sessionData?.isAuthenticated ? (
              <a href="/.auth/logout">
                <Button variant="secondary">Log Off</Button>{' '}
              </a>
            ) : (
              <a href="/.auth/login/aad">
                {' '}
                <Button variant="secondary">Log In</Button>{' '}
              </a>
            )}
          </Stack>
          <Stack direction="horizontal" className="justify-content-between">
            <h1 className="mt-3 mb-0">Snow App</h1>
            <div className="mt-3 mb-0">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  onSyncIconClick();
                }}
                className="mt-3 d-block"
              >
                <FontAwesomeIcon
                  icon={syncStatusIcon}
                  size="lg"
                  color={syncStatusColor}
                />
              </a>
            </div>
          </Stack>
        </div>

        {sessionData?.isAdmin && (
          <div className="d-none d-md-block">
            <Stack direction="horizontal" className="justify-content-between">
              <AppLogo />

              <div className="user-name">
                <DownloadDataModal />
                <span className="last-name">
                  {sessionData?.name || ''}
                </span>{' '}
                <span className="first-name">
                  {sessionData?.userName || ''}
                </span>{' '}
                {sessionData?.isAuthenticated ? (
                  <a href="/.auth/logout">
                    <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
                  </a>
                ) : (
                  <a href="/.auth/login/aad">
                    {' '}
                    <Button variant="secondary">Log In</Button>{' '}
                  </a>
                )}
              </div>
            </Stack>
          </div>
        )}
      </Container>
    </header>
  );
}

AppHeader.propTypes = {
  onSyncIconClick: PropTypes.func.isRequired,
  syncStatus: PropTypes.shape({
    state: PropTypes.string,
    error: PropTypes.string,
  }).isRequired,
  isOnline: PropTypes.bool.isRequired,
};

export default AppHeader;
