import { API_ROOT } from '.';

const getUser = async () => {
  const response = await fetch('/.auth/me');
  const body = await response.json();
  return body;
};

export async function getUserExistsStatus() {
  let userExists = false;
  let unknownError = false;
  let errorStatusCode = null;

  // Check that our user exists in the EAM database by calling
  // the /core/lines endpoint and seeing if we get a 401
  // response
  try {
    const response = await fetch(`${API_ROOT}/core/lines`);
    if (response.status === 401) {
      const { message } = await response.json();
      if (
        message &&
        message.toLowerCase().startsWith('unable to locate user')
      ) {
        userExists = false;
      }
    } else if (response.status === 200) {
      userExists = true;
    } else {
      unknownError = true;
    }

    errorStatusCode = response.status;
  } catch (e) {
    console.error('Unable to determine user-exists status', e);
    unknownError = true;
  }

  return {
    userExists,
    unknownError,
    errorStatusCode,
  };
}

export default getUser;
