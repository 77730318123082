/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo, useState, useRef } from 'react';

import { Container, Row, Modal, Button, Spinner } from 'react-bootstrap';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import { onlineManager } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleExclamation,
  faCircleCheck,
} from '@fortawesome/free-solid-svg-icons';
import {
  useLines,
  useAssets,
  useInspectionTests,
} from './queries/assetQueries';
import { useSession } from './queries/sessionQueries';
import { useActivityQueue } from './hooks/activityQueue';

import AppHeader from './components/AppHeader';
import AppFooter from './components/AppFooter';
import ActivityForm from './components/ActivityForm';
import ActivityGridContainer from './components/Dashboard/ActivityGridContainer';

const getShouldDisplayForm = () => window.innerWidth < 768;

function App() {
  const lineData = useLines();
  const assetData = useAssets();
  const inspectionTestData = useInspectionTests();
  const { data: sessionData, isFetching: sessionIsFetching } = useSession();
  const { sync, add, reset, itemsInQueue, syncStatus } =
    useActivityQueue(sessionData);
  const windowRef = useRef({ currentSize: window.innerWidth });
  const buttonRef = useRef(getShouldDisplayForm());
  const [isOnline, setIsOnline] = useState(true);
  const [syncModalMessages, setSyncModalMessages] = useState([
    'You are online.',
  ]);
  const [isSyncModalOpen, setIsSyncModalOpen] = useState(false);
  const [isUserNotExistModalOpen, setIsUserNotExistModalOpen] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [forcedViewMode, setForcedViewMode] = useState(
    getShouldDisplayForm() ? 'ACTIVITY_FORM' : 'DASHBOARD'
  );
  const [overrideView, setOverrideView] = useState(getShouldDisplayForm());

  const handleOverrideClick = () => {
    if (overrideView) {
      setForcedViewMode('DASHBOARD');
    } else {
      setForcedViewMode('ACTIVITY_FORM');
    }
    setOverrideView(!overrideView);
    buttonRef.current = !buttonRef.current;
  };

  useEffect(() => {
    onlineManager.subscribe(() => {
      setIsOnline(onlineManager.isOnline());
    });
  }, []);

  useEffect(() => {
    if (sessionData?.isAuthenticated && !sessionData?.existsInEamDb) {
      setIsUserNotExistModalOpen(true);
    }
  }, [sessionData?.lastRefreshTimestamp]);

  useEffect(() => {
    const handleResize = () => {
      const lastSize = windowRef.current.currentSize;
      const currentSize = window.innerWidth;
      windowRef.current = { currentSize: window.innerWidth, lastSize };
      // on resize I know the size of the screen.
      // I don't care about the state of the button
      // I just need to force the change
      if (currentSize >= 768 && lastSize < 768) {
        setForcedViewMode('DASHBOARD');
        setOverrideView(false);
        buttonRef.current = false;
      }

      if (currentSize < 768 && lastSize >= 768) {
        setForcedViewMode('ACTIVITY_FORM');
        setOverrideView(true);
        buttonRef.current = false;
      }
    };
    window.addEventListener('resize', handleResize);
  }, [overrideView]);

  useEffect(() => {
    // If we aren't signed in, we will only force the user to sign in if the app
    // doesn't already have the data it needs to make the activity form functional.
    let nextReadyState = false;
    if (
      lineData?.data?.length &&
      assetData?.data?.length &&
      inspectionTestData?.data?.length
    ) {
      nextReadyState = true;
    }
    setIsReady(nextReadyState);
  }, [lineData, assetData, inspectionTestData]);

  useEffect(() => {
    if (
      isOnline &&
      syncStatus.state === 'idle' &&
      itemsInQueue?.length &&
      sessionData?.isAuthenticated
    ) {
      sync();
    }

    const nextMessages = [];

    if (syncStatus.state === 'syncing') {
      nextMessages.push(
        `Submitting activities to server... Activities submitted: ${syncStatus.itemsCompleted}`
      );
    }

    if (!isOnline) {
      nextMessages.push(
        'You are offline. Activities are still saved to your device, but you will need to open the app to finish submitting your activities when you are online again.'
      );
    } else if (syncStatus.state === 'idle') {
      nextMessages.push('You are online.');
    }

    if (!sessionData?.isAuthenticated) {
      nextMessages.push(
        'You are not logged in. Activities will not be submitted to the server until you log in.'
      );
    }

    if (syncStatus.state === 'stopped') {
      if (syncStatus.error) {
        nextMessages.push(
          <>
            <FontAwesomeIcon
              icon={faCircleExclamation}
              size="lg"
              color="#fe4a4a"
            />{' '}
            Activity submission failed because of a network or server error, but
            the activity has been saved to your device. The application will try
            to submit the activity again.
          </>
        );
      } else {
        nextMessages.push(
          <>
            <FontAwesomeIcon icon={faCircleCheck} size="lg" color="#00abc9" />{' '}
            All activities have been submitted.
          </>
        );
      }
    }

    if (itemsInQueue?.length) {
      nextMessages.push(`Activities saved on device: ${itemsInQueue.length}`);
    }

    setSyncModalMessages(nextMessages);
  }, [isOnline, syncStatus, itemsInQueue, sessionData]);

  const isLoading = useMemo(() => {
    if (!isReady) {
      if (
        lineData?.isFetching ||
        assetData?.isFetching ||
        inspectionTestData?.isFetching ||
        sessionIsFetching
      ) {
        return true;
      }
    }
    return false;
  }, [
    isReady,
    lineData?.isFetching,
    assetData?.isFetching,
    inspectionTestData?.isFetching,
    sessionIsFetching,
  ]);

  let activityFormVisibilityClass = 'd-block';
  let dashboardVisibilityClass = 'd-none d-md-block';

  if (sessionData?.isAdmin) {
    if (forcedViewMode === 'DASHBOARD') {
      activityFormVisibilityClass = 'd-none';
      dashboardVisibilityClass = 'd-block';
    } else if (forcedViewMode === 'ACTIVITY_FORM') {
      activityFormVisibilityClass = 'd-block';
      dashboardVisibilityClass = 'd-none';
    } else {
      activityFormVisibilityClass = 'd-md-none';
    }
  }

  return (
    <div className="d-flex flex-column justify-content-between min-vh-100">
      <div>
        <AppHeader
          onSyncIconClick={() => setIsSyncModalOpen(true)}
          syncStatus={syncStatus}
          isOnline={isOnline}
        />

        {isReady && (
          <>
            {sessionData?.isAdmin && (
              <div className="d-flex justify-content-end my-3 me-3">
                <span style={{ lineHeight: '40px', paddingRight: '10px' }}>
                  Current View
                </span>
                <BootstrapSwitchButton
                  width={200}
                  onlabel="Submit an Activity"
                  offlabel="Dashboard"
                  checked={overrideView}
                  onChange={() => {
                    handleOverrideClick();
                  }}
                />
              </div>
            )}

            <div className={`${activityFormVisibilityClass} my-4`}>
              <ActivityForm
                lineData={lineData.data}
                assetData={assetData.data}
                inspectionTestData={inspectionTestData.data}
                onSubmitActivity={(activity, images) => {
                  add(activity, images);
                  setIsSyncModalOpen(true);
                }}
              />
            </div>

            {sessionData?.isAdmin && (
              <div className={`admin-view my-4 ${dashboardVisibilityClass}`}>
                <Container fluid>
                  <Row>
                    <ActivityGridContainer
                      lineData={lineData.data}
                      assetData={assetData.data}
                      inspectionTestData={inspectionTestData.data}
                    />
                  </Row>
                </Container>
              </div>
            )}
          </>
        )}
      </div>

      {!isReady && (
        <div className="text-center">
          {isLoading ? (
            <div style={{ fontSize: 'xx-large' }}>
              <Spinner animation="border" role="status" />
            </div>
          ) : (
            <div style={{ fontSize: 'xx-large' }}>
              {sessionData?.isAuthenticated ? (
                <>Unable to load application data</>
              ) : (
                <>
                  Please <a href="/.auth/login/aad">log in</a> to continue
                </>
              )}
            </div>
          )}
        </div>
      )}

      <AppFooter isOnline={isOnline} />

      <Modal show={isSyncModalOpen} centered>
        <Modal.Body>
          {syncModalMessages.map((msg) => (
            <p key={msg}>{msg}</p>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setIsSyncModalOpen(false);
              reset();
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isUserNotExistModalOpen} centered>
        <Modal.Body>
          <p>
            <strong>USER ERROR:</strong> You are logged in, but your user does
            not exist in the EAM database. Please contact your supervisor.
          </p>
          <p>
            This application will not function until this issue is resolved.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              setIsUserNotExistModalOpen(false);
            }}
          >
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default App;
