import React from 'react';
import PropTypes from 'prop-types';

function AppFooter({ isOnline }) {
  return (
    <div>
      {!!process.env.REACT_APP_BUILD_COMMIT && (
        <div className="m-2 mt-5 text-muted">
          Version:{' '}
          <span>
            {process.env.REACT_APP_BUILD_COMMIT.substring(0, 6)}:
            {process.env.REACT_APP_BUILD_DATE}
          </span>
          {isOnline && (
            <a
              className="d-block"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                // This may be unnecessary but this blows away the service
                // worker's cache of static assets like the JS bundle
                // and index.html, forcing the browser to refetch them
                // over the network. That's the idea anyway...
                if (window.caches) {
                  window.caches.keys().then((names) => {
                    const deletionPromises = names.map((name) =>
                      window.caches.delete(name)
                    );
                    Promise.all(deletionPromises).then(() => {
                      window.location.reload();
                    });
                  });
                }
              }}
            >
              Check for update
            </a>
          )}
        </div>
      )}
    </div>
  );
}

AppFooter.propTypes = {
  isOnline: PropTypes.bool.isRequired,
};

export default AppFooter;
