import { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
    this.appInsights = props.appInsights;
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error boundary caught an exception.', error, errorInfo);
    this.appInsights.trackException({ exception: error });
  }

  render() {
    const { hasError, error } = this.state;

    // eslint-disable-next-line react/prop-types
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <Container>
          <Row className="justify-content-center">
            <Col sm={12} md={8} className="my-5">
              <p>
                SNAPP has encountered an error that it can&apos;t recover from.{' '}
                <a href="/">Reload application.</a>
              </p>
              <p>
                Details (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    if (navigator.clipboard) {
                      navigator.clipboard
                        .writeText(
                          `${new Date()} | ${error.message} | ${error.stack}`
                        )
                        .then(() => {
                          // eslint-disable-next-line no-alert
                          alert('Copied error details to clipboard.');
                        });
                    }
                  }}
                >
                  Copy to clipboard
                </a>
                ):
              </p>
              <pre style={{ whiteSpace: 'pre-wrap' }}>{error.message}</pre>
              <pre style={{ whiteSpace: 'pre-wrap' }}>{error.stack}</pre>
            </Col>
          </Row>
        </Container>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  appInsights: PropTypes.object.isRequired,
};
