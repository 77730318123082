/* eslint-disable prefer-destructuring */
import { useQuery } from '@tanstack/react-query';
import { getAssets, getLines, getInspectionTests, getActivities } from '../api';

export function useAssets() {
  return useQuery(['assets'], async () => getAssets(), {
    cacheTime: Infinity,
    staleTime: 1000 * 60 * 60 * 24 * 2,
  });
}

export function useLines() {
  return useQuery(['lines'], async () => getLines(), {
    cacheTime: Infinity,
    staleTime: 1000 * 60 * 60 * 24 * 2,
  });
}

export function useInspectionTests() {
  return useQuery(['inspection tests'], async () => getInspectionTests(), {
    cacheTime: Infinity,
    staleTime: 1000 * 60 * 60 * 24 * 2,
  });
}

export function useActivities() {
  return useQuery(['activities'], async () => getActivities(), {
    cacheTime: Infinity,
    staleTime: 30 * 1000,
    refetchInterval: 30 * 1000,
  });
}
