/* eslint-disable no-await-in-loop */
import { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { saveActivity, saveImage } from '../api';

const ACTIVITY_QUEUE_KEY = 'activityQueue';

async function syncActivity(activityPackage) {
  if (activityPackage.savedWorkOrderId) {
    return activityPackage.savedWorkOrderId;
  }

  const savedActivity = await saveActivity(activityPackage.data.activity);
  return savedActivity.id;
}

async function syncImages(workOrderId, activityPackage) {
  const { images } = activityPackage.data;

  for (let i = 0; i < images.length; i += 1) {
    const image = images[i];
    await saveImage(image, workOrderId);
  }
}

function updateActivityInQueue(queryClient, activityPackage) {
  queryClient.setQueryData([ACTIVITY_QUEUE_KEY], (previous) => {
    const next = previous.map((otherPackage) => {
      if (otherPackage.id === activityPackage.id) {
        return activityPackage;
      }
      return otherPackage;
    });
    return next;
  });
}

function removeActivityFromQueue(queryClient, activityPackageId) {
  queryClient.setQueryData([ACTIVITY_QUEUE_KEY], (previous) =>
    previous.filter(
      (activityPackage) => activityPackage.id !== activityPackageId
    )
  );
}

/**
 * Provides the activity submission queue. You should only use one
 * instance of this hook in the entire application, because it
 * doesn't use a context provider, so multiple copies of the hook
 * will create multiple queues (not one shared queue).
 */
export function useActivityQueue(sessionData) {
  const defaultSyncState = {
    error: null,
    state: 'idle',
    itemsCompleted: 0,
  };

  const queryClient = useQueryClient();
  const [syncStatus, setSyncStatus] = useState({ ...defaultSyncState });
  const activityQueue = useQuery(
    [ACTIVITY_QUEUE_KEY],
    () =>
      // This query is used to store unsubmitted activities temporarily
      // on the user's device, so our default fetch function doesn't fetch
      // any data from a remote source; it just provides the data that it
      // already has.
      queryClient.getQueryData([ACTIVITY_QUEUE_KEY]) || [],
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    }
  );

  const { data: itemsInQueue } = activityQueue;

  /**
   * Attempt to submit all items in the queue to the API. If it encounters
   * an error it will stop and the `syncStatus` object returned by
   * `useActivityQueue`will be updated accordingly. As each item is
   * succesfully submitted to the API the item will be removed from the
   * queue.
   */
  const sync = async () => {
    if (syncStatus.state === 'syncing') {
      return;
    }

    setSyncStatus({ ...defaultSyncState, state: 'syncing' });

    const queueItems = queryClient.getQueryData([ACTIVITY_QUEUE_KEY]);
    if (queueItems.length) {
      try {
        for (let i = 0; i < queueItems.length; i += 1) {
          // The general idea: each item (activity "package") in the queue
          // contains an activity payload and 0 or more image payloads
          // associated with the activity. First we submit the activity, and
          // if that works we submit each of its images (serially, not in
          // parallel). Once an activity and each of its images have been
          // successfully shipped off, we remove the package from the queue
          // and move on to the next one.
          const activityPackage = { ...queueItems[i] };

          const savedWorkOrderId = await syncActivity(activityPackage);

          // We update the package in the queue to indicate that it was
          // successfully saved (by setting its workOrderId). This way, if
          // the sync halts later when uploading the images, next time we
          // run through the sync queue, we'll know to skip the activity
          // because it's already been saved. Remember that an activity's
          // "package" is not removed from the queue until it AND its images
          // have all be saved to the remote API.
          updateActivityInQueue(queryClient, {
            ...activityPackage,
            savedWorkOrderId,
          });

          await syncImages(savedWorkOrderId, activityPackage);

          removeActivityFromQueue(queryClient, activityPackage.id);

          setSyncStatus((status) => ({
            ...status,
            itemsCompleted: status.itemsCompleted + 1,
          }));
        }
      } catch (err) {
        setSyncStatus((status) => ({
          ...status,
          error: 'Failed to sync one or more items.',
        }));
      } finally {
        setSyncStatus((status) => ({ ...status, state: 'stopped' }));
        if (sessionData?.isAdmin) {
          queryClient.invalidateQueries(['activities']);
          queryClient.prefetchQuery(['activities']).then(() => {
            console.log('Refetched activities.');
          });
        }
      }
    }
  };

  /**
   * Add an activity item to the queue for later submission to the API. Items added
   * to the queue will not be synced until the `sync` function is called explicitly.
   *
   * @param {object} activity - the activity payload
   * @param {object[]} images - array of image payloads
   */
  const add = (activity, images) => {
    const activityPackage = {
      id: Math.random().toString(36).substring(2, 6), // Make IDs like: cw2vf3, qegqtf
      dateAdded: Date.now(),
      savedWorkOrderId: null,
      data: {
        activity,
        images: images?.length ? images : [],
      },
    };

    queryClient.setQueryData([ACTIVITY_QUEUE_KEY], (previous) => {
      if (previous && previous.length) {
        return [...previous, activityPackage];
      }
      return [activityPackage];
    });
  };

  /**
   * Resets the sync queue status. This doesn't clear items from the queue. It
   * just resets the status (such as error state and other queue related feedback)
   * back to its default state. Use this for example when displaying the queue
   * status in a modal in the UI; when the user dismisses the modal you reset the
   * state of the queue.
   */
  const reset = () => {
    if (syncStatus.state !== 'syncing') {
      setSyncStatus({ ...defaultSyncState });
    }
  };

  return {
    itemsInQueue,

    /**
     * An object with the properties `state`, `error` and `itemsCompleted`.
     *
     * If the queue is not currently syncing the `state` will be 'idle'. Once
     * `sync` is called, the `state` will be 'syncing'.
     *
     * When the queue has finished syncing (either because it successfully
     * submitted all items, or stopped because of an error) the `state` will
     * be 'stopped'.
     *
     * If the sync encountered an error the `error` property will contain
     * the error message (otherwise it will be null).
     *
     * The `itemsCompleted` property resets to the value 0 whenever
     * `sync` is called, and the value increases as each activity is
     * successfully submitted.
     */
    syncStatus,

    sync,
    add,
    reset,
  };
}
