/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState } from 'react';
import PropTypes, { number, string, bool, func } from 'prop-types';

import { Table, Card, Badge, Modal, Stack, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
  faChevronLeft,
  faChevronRight,
  faXmark,
  faPenToSquare,
  faImages,
} from '@fortawesome/free-solid-svg-icons';
import { chunk, sortBy } from 'lodash';
import formatDate from 'date-fns/format';
import ActivityEditModal from '../../modals/ActivityEditModal';
import { API_ROOT } from '../../api';
import { DATE_RENDER_FORMAT } from '../../shared/helpers';

function ActivityGrid(props) {
  const { assets, lineData, assetData, inspectionTestData, refetchActivities } =
    props;

  const [activeModal, setActiveModal] = useState(false);

  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedTestResults, setSelectedTestResults] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(false);
  const [chunkedActivities, setChunkedActivities] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [sortOrder, setSortOrder] = useState({
    column: false,
    direction: false,
  });

  const setSelectedAssetActivities = (asset) => {
    if (asset) {
      const activityCopy = [...asset.activities] || [];
      activityCopy.reverse();
      const preparedActivities = chunk(activityCopy, 10);
      setChunkedActivities(preparedActivities);
    }
  };

  useEffect(() => {
    if (selectedAsset) {
      setSelectedAssetActivities(selectedAsset);
    }
  }, [assets]);

  const handleShowActivityList = (asset) => {
    setSelectedAssetActivities(asset);
    setSelectedAsset(asset);
    setActiveModal('activity list');
  };

  const handleHideActivityList = () => {
    setSelectedPage(1);
    setActiveModal(false);
  };

  const handleHideActivityEdit = () => {
    setSelectedActivity(false);
    setActiveModal('activity list');
  };

  const handleModalFlip = () => {
    setActiveModal('activity edit');
  };

  const handleShowPhotos = (testResults) => {
    setSelectedTestResults(testResults);
    setActiveModal('photo');
  };

  const handleHidePhotos = () => {
    setActiveModal('activity list');
  };

  const handlePageChange = (direction) => {
    // eslint-disable-next-line default-case
    switch (direction) {
      case 'forward':
        if (selectedPage === chunkedActivities.length) {
          return;
        }
        const nextPageForward = selectedPage + 1;
        setSelectedPage(nextPageForward);
        break;
      case 'back':
        if (selectedPage === 1) {
          return;
        }
        const nextPageBack = selectedPage - 1;
        setSelectedPage(nextPageBack);
        break;
    }
  };

  const getSortIcon = (column) => {
    // if there is no sort direction, everything is the up/down chevron
    if (!sortOrder.column) {
      return faSort;
    }
    // if the sort order's column matches the column being examined, return an
    // up or down

    if (sortOrder.column === column) {
      return sortOrder.direction ? faSortUp : faSortDown;
    }

    // if this isn't the sorted column, return the double arrow sort
    return faSort;
  };

  const handleSortClick = (clickedColumn) => {
    // if there's no selected column OR the clicked column is a different one,
    // set the sort to {column: column, direction: true}
    if (sortOrder.column !== clickedColumn) {
      // console.log('clicked a new column, setting sort to true')
      setSortOrder({ column: clickedColumn, direction: true });
      return;
    }
    // if I'm clicking the column AGAIN, I need to advance the sort direction
    if (sortOrder.column === clickedColumn && sortOrder.direction) {
      // console.log('clicked an up column, setting sort to false')
      setSortOrder({ ...sortOrder, direction: false });
    } else {
      setSortOrder({ column: false, direction: false });
    }
  };

  const sortAssetsByProperty = (inputArray) => {
    if (!sortOrder.column) {
      return inputArray;
    }
    let sortedAssets = [];

    if (sortOrder.column === 'activities') {
      const sortedByActivity = sortBy(inputArray, (asset) => {
        let activityCount = 0;
        const badgeArray = [
          asset.clearBadge,
          asset.passBadge,
          asset.preSaltBadge,
          asset.saltBadge,
        ];
        badgeArray.forEach((badge) => {
          if (badge !== 'secondary') {
            // most activities on top requires a -- count
            activityCount -= 1;
          }
        });
        return activityCount;
      });

      if (!sortOrder.direction) {
        sortedByActivity.reverse();
      }
      return sortedByActivity;
    }

    if (sortOrder.column === 'lastActivityTime') {
      const sortedByLastActivity = sortBy(inputArray, (asset) => {
        const { lastActivityTime } = asset;
        let date =
          lastActivityTime && typeof lastActivityTime !== 'string'
            ? lastActivityTime
            : new Date(0);

        if (!date.toISOString) {
          date = new Date(0);
        }
        return date.toISOString();
      });

      if (sortOrder.direction) {
        // the default sortBy returns the newest activities at the bottom
        // therefore, the .reverse() logic is flipped on this one.
        sortedByLastActivity.reverse();
      }
      return sortedByLastActivity;
    }

    sortedAssets = sortBy(inputArray, (asset) => asset[sortOrder.column]);

    if (!sortOrder.direction) {
      sortedAssets.reverse();
    }
    return sortedAssets;
  };

  return (
    <>
      <Card className="activity-grid mb-3">
        <Card.Body>
          <div className="table-fix-head">
            <Table striped hover className="text-center">
              <thead>
                <tr>
                  <th onClick={() => handleSortClick('lineId')}>
                    Route{' '}
                    <FontAwesomeIcon
                      icon={getSortIcon('lineId')}
                      className="text-primary"
                    />
                  </th>
                  <th onClick={() => handleSortClick('description')}>
                    Asset Name{' '}
                    <FontAwesomeIcon
                      icon={getSortIcon('description')}
                      className="text-primary"
                    />
                  </th>
                  <th onClick={() => handleSortClick('categoryId')}>
                    Asset Type{' '}
                    <FontAwesomeIcon
                      icon={getSortIcon('categoryId')}
                      className="text-primary"
                    />
                  </th>
                  <th onClick={() => handleSortClick('activities')}>
                    Activities{' '}
                    <FontAwesomeIcon
                      icon={getSortIcon('activities')}
                      className="text-primary"
                    />
                  </th>
                  <th onClick={() => handleSortClick('lastActivityTime')}>
                    Last Activity Time{' '}
                    <FontAwesomeIcon
                      icon={getSortIcon('lastActivityTime')}
                      className="text-primary"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {assets &&
                  sortAssetsByProperty([...assets]).map((asset) => (
                    <tr
                      key={asset.id}
                      onClick={() => handleShowActivityList(asset)}
                    >
                      <td>{asset.displayName}</td>
                      <td>{asset.description}</td>
                      <td>{asset.categoryId}</td>
                      <td style={{ whiteSpace: 'nowrap' }}>
                        <Badge pill bg={asset.preSaltBadge} className="mx-1">
                          PS
                        </Badge>
                        <Badge pill bg={asset.passBadge}>
                          P
                        </Badge>
                        <Badge pill bg={asset.clearBadge} className="mx-1">
                          C
                        </Badge>
                        <Badge pill bg={asset.saltBadge}>
                          S
                        </Badge>
                      </td>
                      <td>{asset.lastActivity || 'N/A'}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Card.Body>
      </Card>
      <Modal
        size="xl"
        show={activeModal === 'activity list'}
        onHide={handleHideActivityList}
        centered
      >
        <Modal.Title className="text-center">
          <Stack
            direction="horizontal"
            className="justify-content-space-between bg-secondary text-white"
          >
            <h4 className="ms-auto text-white pt-2">Activity Log</h4>
            <a
              href
              className="ms-auto text-white px-2"
              onClick={(event) => {
                event.preventDefault();
                handleHideActivityList();
              }}
            >
              <FontAwesomeIcon icon={faXmark} color="#ffffff" size="lg" />
            </a>
          </Stack>
        </Modal.Title>
        <Modal.Body>
          <div className="text-center mb-3"> {selectedAsset?.description}</div>
          <Table striped hover bordered className="text-center">
            <thead>
              <tr>
                <th>Division</th>
                <th>Date/Time</th>
                <th>Activity</th>
                <th>Employee Name</th>
                <th>Photos</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {selectedAsset &&
                chunkedActivities[selectedPage - 1] &&
                chunkedActivities[selectedPage - 1].map((activity) => {
                  const imageCount = activity.testResults.filter(
                    (test) => test.imagePath
                  ).length;

                  let activityDate = new Date();
                  if (activity.testResults[0]?.datetimeTested) {
                    activityDate = new Date(
                      activity.testResults[0].datetimeTested
                    );
                  }

                  return (
                    <tr key={activity.id}>
                      <td>{selectedAsset.id}</td>
                      <td>{formatDate(activityDate, DATE_RENDER_FORMAT)}</td>
                      <td>{activity.testResults[0]?.qualitativeFinding}</td>
                      <td>{activity?.workDoneBy}</td>
                      <td>
                        {!!imageCount && (
                          <>
                            <a
                              href="#"
                              onClick={(event) => {
                                event.preventDefault();
                                handleShowPhotos(activity.testResults);
                              }}
                            >
                              <FontAwesomeIcon icon={faImages} />
                            </a>{' '}
                            ({imageCount})
                          </>
                        )}
                      </td>
                      <td>
                        <a
                          href="#"
                          onClick={(event) => {
                            event.preventDefault();
                            setSelectedActivity(activity);
                            handleModalFlip();
                          }}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </a>
                      </td>
                    </tr>
                  );
                })}
              <tr />
            </tbody>
          </Table>
          {chunkedActivities.length === 0 && (
            <p>No activities have been found for this asset.</p>
          )}
          <Stack direction="horizontal" gap={3} className="justify-content-end">
            {selectedPage > 1 && (
              <Button
                variant="outline"
                onClick={() => handlePageChange('back')}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </Button>
            )}

            {chunkedActivities.length > 0 && <div> Page {selectedPage} </div>}

            {selectedPage < chunkedActivities.length && (
              <Button
                variant="outlined"
                onClick={() => handlePageChange('forward')}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </Button>
            )}
          </Stack>
        </Modal.Body>
      </Modal>
      <Modal
        size="xl"
        show={activeModal === 'activity edit'}
        onHide={handleHideActivityEdit}
        centered
      >
        <Modal.Title className="text-center">
          <Stack
            direction="horizontal"
            className="justify-content-space-between bg-secondary text-white"
          >
            <h4 className="ms-auto text-white pt-2">Edit Activity</h4>
            <a
              href
              className="ms-auto text-white px-2"
              onClick={(event) => {
                event.preventDefault();
                handleHideActivityEdit();
              }}
            >
              <FontAwesomeIcon icon={faXmark} color="#ffffff" size="lg" />
            </a>
          </Stack>
        </Modal.Title>
        <Modal.Body>
          {selectedActivity && (
            <ActivityEditModal
              handleClose={handleHideActivityEdit}
              selectedAsset={selectedAsset}
              assetLine={selectedAsset.lineId}
              assetType={selectedAsset.type}
              selectedActivity={selectedActivity}
              lineData={lineData}
              assetData={assetData}
              inspectionTestData={inspectionTestData}
              refetchActivities={refetchActivities}
            />
          )}
        </Modal.Body>
      </Modal>
      <Modal
        size="xl"
        show={activeModal === 'photo'}
        onHide={handleHidePhotos}
        centered
      >
        <Modal.Title className="text-center">
          <Stack
            direction="horizontal"
            className="justify-content-space-between bg-secondary text-white"
          >
            <h4 className="ms-auto text-white pt-2">
              {selectedAsset?.description}
            </h4>
            <a
              href
              className="ms-auto text-white px-2"
              onClick={(event) => {
                event.preventDefault();
                handleHidePhotos();
              }}
            >
              <FontAwesomeIcon icon={faXmark} color="#ffffff" size="lg" />
            </a>
          </Stack>
        </Modal.Title>
        <Modal.Body>
          {selectedTestResults &&
            selectedTestResults
              .filter((testResult) => testResult.imagePath)
              .map((testWithImage) => (
                <Card key={testWithImage.testElementId}>
                  <Card.Img
                    variant="bottom"
                    src={`${API_ROOT}${testWithImage.imagePath}`}
                  />
                </Card>
              ))}
        </Modal.Body>
      </Modal>
    </>
  );
}

ActivityGrid.defaultProps = {
  assets: [],
  lineData: [],
  assetData: [],
  inspectionTestData: [],
  refetchActivities: func,
};

ActivityGrid.propTypes = {
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      eamId: number,
      id: string,
      lineId: string,
      displayName: string,
      description: string,
      title: string,
      lastActivity: string,
    })
  ),
  lineData: PropTypes.arrayOf(
    PropTypes.shape({
      eamId: number,
      id: string,
      description: string,
    })
  ),
  assetData: PropTypes.arrayOf(
    PropTypes.shape({
      activities: PropTypes.arrayOf(
        PropTypes.shape({
          assetId: string,
          comments: string,
          id: number,
          jobType: string,
          taskId: string,
          testResults: PropTypes.arrayOf(
            PropTypes.shape({
              comments: string,
              datetimeTested: string,
              id: number,
              imagePath: string,
              qualitativeFinding: string,
              testElementId: string,
              workOrderId: number,
            })
          ),
          userId: string,
        })
      ),
      assetClass: string,
      assetNumber: string,
      attributes: PropTypes.arrayOf(
        PropTypes.shape({
          active: string,
          assetId: string,
          dateValue: string,
          displayOrder: number,
          id: number,
          name: string,
          numericValue: number,
          subsystem: string,
          textValue: string,
          value: string,
        })
      ),
      categoryId: string,
      description: string,
      displayName: string,
      eamId: number,
      id: string,
      lastClear: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lastPass: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lastPreSalt: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lastSalt: PropTypes.shape({
        assetId: string,
        comments: string,
        id: number,
        jobType: string,
        taskId: string,
        testResults: PropTypes.arrayOf(
          PropTypes.shape({
            comments: string,
            datetimeTested: string,
            id: number,
            imagePath: string,
            qualitativeFinding: string,
            testElementId: string,
            workOrderId: number,
          })
        ),
        userId: string,
      }),
      lineId: string,
      milepost: string,
      serialNumber: string,
      title: string,
      type: string,
      ok: bool,
      preSaltBadge: string,
      saltBadge: string,
      passBadge: string,
      clearBadge: string,
    })
  ),
  inspectionTestData: PropTypes.arrayOf(
    PropTypes.shape({
      allowFile: bool,
      assetClass: string,
      checklist: PropTypes.arrayOf(
        PropTypes.shape({
          id: number,
          checklistType: string,
          value: string,
          description: string,
        })
      ),
      checklistType: string,
      description: string,
      displayOrder: number,
      eamId: number,
      id: string,
      requireFile: bool,
      testId: string,
    })
  ),
  refetchActivities: func,
};

export default ActivityGrid;
