/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes, { func } from 'prop-types';

import { Container, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import heic2any from 'heic2any';
import ImageUploading from 'react-images-uploading';
// import exifr from 'exifr';
// import piexif from 'piexifjs';
import { getImage } from '../api/index';

const imageReducer = new window.ImageBlobReduce({
  pica: window.ImageBlobReduce.pica({ features: ['js', 'wasm', 'ww'] }),
});

function ImageSubmit(props) {
  const {
    setLoading,
    handleImage,
    imageIndex,
    location,
    workOrderId,
    testElementId,
  } = props;

  const [images, setImages] = useState([]);

  const handleImageUpload = useCallback(async (imageList, addUpdateIndex) => {
    setLoading(true);
    const nextImages = await Promise.all(
      imageList.map(async (selectedFileInfo, index) => {
        const imageInfo = selectedFileInfo;
        const { file } = imageInfo;
        if (addUpdateIndex && addUpdateIndex.includes(index)) {
          let jpgFile;

          // Convert HEIC to JPEG
          if (file.name.toLowerCase().endsWith('heic')) {
            // const exifData = await exifr.parse(file);

            const buf = await file.arrayBuffer();
            const jpgBlob = await heic2any({
              blob: new Blob([buf]),
              toType: 'image/jpeg',
              quality: 0.5,
            });

            /*
            const jpgString = await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onload = () => resolve(reader.result);
              reader.readAsBinaryString(jpgBlob);
            });
            */

            // const exifStr = piexif.dump(exifData);
            // jpgString = piexif.insert(exifStr, jpgString);

            jpgFile = new File(
              // [new Blob([jpgString], { type: 'application/octet-stream' })],
              [jpgBlob],
              'converted.jpg',
              {
                type: 'image/jpeg',
              }
            );
          } else {
            jpgFile = file;
          }

          // Scale the image down
          const resizedJpgBlob = await imageReducer.toBlob(jpgFile, {
            max: 700,
            unsharpAmount: 80,
            unsharpRadius: 0.6,
            unsharpThreshold: 2,
          });

          imageInfo.file = new File([resizedJpgBlob], 'processed.jpg', {
            type: 'image/jpeg',
          });

          imageInfo.dataUrl = await new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(resizedJpgBlob);
          });
        }
        return imageInfo;
      })
    );
    setImages(nextImages);
    setLoading(false);
    handleImage(nextImages, imageIndex, location);
  }, []);

  useEffect(() => {
    if (workOrderId) {
      const getPhoto = async () => {
        const photoBlob = await getImage(workOrderId, testElementId);
        photoBlob.file = new File([photoBlob], 'converted.jpg', {
          type: 'image/jpeg',
        });
        photoBlob.dataUrl = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.readAsDataURL(photoBlob);
        });
        setImages([photoBlob]);
        handleImage([photoBlob], imageIndex, location);
      };
      getPhoto();
    }
  }, [workOrderId]);

  return (
    <div>
      <Form.Group className="mb-3">
        <Form.Label>{location}</Form.Label>
      </Form.Group>
      <ImageUploading
        value={images}
        onChange={handleImageUpload}
        allowNonImageType
        maxNumber={5}
        dataURLKey="dataUrl"
      >
        {({ imageList, onImageUpload, onImageUpdate, dragProps }) => (
          <Form.Group className="mb-2">
            <Container className="mt-2">
              {images.length === 0 && (
                <div
                  className="photo-box"
                  onClick={onImageUpload}
                  {...dragProps}
                >
                  <div className="mb-4">Add Picture Required</div>
                  <FontAwesomeIcon
                    icon={faImage}
                    size="3x"
                    className="text-secondary"
                  />
                </div>
              )}
              {imageList.map((image, index) => {
                if (image) {
                  return (
                    <Row key={image.file?.name}>
                      <Col md={2}>
                        <img
                          onClick={() => onImageUpdate(index)}
                          src={image.dataUrl}
                          alt=""
                          style={{
                            objectFit: 'cover',
                            maxHeight: 100,
                            maxWidth: 120,
                          }}
                        />
                      </Col>
                    </Row>
                  );
                }
                return (
                  <div className="photo-box">
                    <div className="mb-4">Add Picture Required</div>
                    <FontAwesomeIcon
                      icon={faImage}
                      size="3x"
                      className="text-secondary"
                    />
                  </div>
                );
              })}
            </Container>
          </Form.Group>
        )}
      </ImageUploading>
    </div>
  );
}

export default ImageSubmit;

ImageSubmit.defaultProps = {
  setLoading: func,
  handleImage: func,
  imageIndex: 0,
  location: '',
  testElementId: '',
  workOrderId: false,
};

ImageSubmit.propTypes = {
  setLoading: PropTypes.func,
  handleImage: PropTypes.func,
  imageIndex: PropTypes.number,
  location: PropTypes.string,
  workOrderId: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  testElementId: PropTypes.string,
};
