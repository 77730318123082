import React from 'react';
import PropTypes, { func, number } from 'prop-types';

import { Row, Col, Card, Form, Button } from 'react-bootstrap';

function ActivityStatusRules(props) {
  const { statusRules, handleStatusRulesChange, resetStatusRules } = props;

  return (
    <Card className="mb-4">
      <Card.Header className="bg-secondary text-white text-center fw-bold">
        Status Rules
      </Card.Header>
      <Card.Body className="fw-bold">
        <Row className="mb-3">
          <Col>Frequency</Col>
        </Row>
        <Row>
          <Col md={3}>Pre-Salt</Col>
          <Col>
            <Form.Range
              id="preSalt"
              min="0"
              max="36"
              value={statusRules.preSalt}
              onChange={(event) =>
                handleStatusRulesChange('preSalt', event.target.value)
              }
            />
          </Col>
          <Col md={2}>{`${statusRules.preSalt}h`}</Col>
        </Row>
        <Row>
          <Col md={3}>Pass</Col>
          <Col>
            <Form.Range
              id="pass"
              min="0"
              max="36"
              value={statusRules.pass}
              onChange={(event) =>
                handleStatusRulesChange('pass', event.target.value)
              }
            />
          </Col>
          <Col md={2}>{`${statusRules.pass}h`}</Col>
        </Row>
        <Row>
          <Col md={3}>Clear</Col>
          <Col>
            <Form.Range
              id="clear"
              min="0"
              max="36"
              value={statusRules.clear}
              onChange={(event) =>
                handleStatusRulesChange('clear', event.target.value)
              }
            />
          </Col>
          <Col md={2}>{`${statusRules.clear}h`}</Col>
        </Row>
        <Row>
          <Col md={3}>Salted</Col>
          <Col>
            <Form.Range
              id="salted"
              min="0"
              max="36"
              value={statusRules.salted}
              onChange={(event) =>
                handleStatusRulesChange('salted', event.target.value)
              }
            />
          </Col>
          <Col md={2}>{`${statusRules.salted}h`}</Col>
        </Row>
        <div className="text-center mt-3">
          <Button size="sm" onClick={() => resetStatusRules()}>
            Clear Rules
          </Button>
        </div>
      </Card.Body>
    </Card>
  );
}

export default ActivityStatusRules;

ActivityStatusRules.defaultProps = {
  statusRules: {},
  handleStatusRulesChange: func,
  resetStatusRules: func,
};

ActivityStatusRules.propTypes = {
  statusRules: PropTypes.shape({
    preSalt: number,
    pass: number,
    clear: number,
    salted: number,
  }),
  handleStatusRulesChange: PropTypes.func,
  resetStatusRules: PropTypes.func,
};
