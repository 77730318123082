import { Modal, Spinner } from 'react-bootstrap';

function ImageModal(props) {
  return (
    <Modal
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="image-modal"
    >
      <Modal.Body>
        <h4>Loading...</h4>
        <Spinner animation="border" role="status" />
      </Modal.Body>
    </Modal>
  );
}

export default ImageModal;
