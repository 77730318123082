import { useQuery } from '@tanstack/react-query';
import { getAllUsers } from '../api';

export const useAllUsers = (currentUser) =>
  useQuery({
    queryKey: ['all-users'],
    enabled: currentUser?.isAdmin,
    queryFn: async () => getAllUsers(),
    cacheTime: 1000 * 120,
    staleTime: 1000 * 3600,
  });
